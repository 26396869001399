import React, { Component } from 'react'
import { connect } from 'react-redux'

class PowerPlansComponent extends Component {
  render() {
    const { plans, item } = this.props
    return (
      <div>
        <h1>Velg abonnement</h1>
        {plans.map(plan => {
          const priceStr = ` (${plan.amount || ''}/mnd)`
          return (
            <div className="input_wrapper" key={`plan-${plan.id}`}>
              <input type="radio" value={plan.id} id={`plan-${plan.id}`} checked={plan.id === item.planId} onClick={e => this.props.changePlan(e.target.value)} onChange={e => {}} />
              <label htmlFor={`plan-${plan.id}`}><span>{`${plan.name}${priceStr}`}</span></label>
            </div>
          )}
        )}
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    layout: state.layout,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PowerPlansComponent)
