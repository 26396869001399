import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'

import moment from 'moment'
import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'

import config from '../../../../../config'
import { setFormData } from '../../../../../actions/formData'

const prepaidSubscriptionIds = ['c92f0456-5bea-4605-ab92-8c22ea7aaa8a']

class SubscriptionsComponent extends Component {


  render() {
    const { item, subscriptions, dispatch, formData, layout } = this.props
    const { departmentId } = layout.data
    const { chatDepartmentIds } = config
    const isChatDept = chatDepartmentIds.includes(departmentId)

    // Remove family stuff, which can be considered "virtual" subs
    const nonEligibleSubIds = [config.sharedDataOwnerPlan, config.sharedDataMemberPlan]
    const eligibleSubs = subscriptions.filter(s => !nonEligibleSubIds.includes(s.id))

    // Allow chat depts (Kontrex) to see prepaid plans and Mobilt Bredbånd plans
    let visibleSubs = isChatDept ? eligibleSubs : eligibleSubs.filter(s => !prepaidSubscriptionIds.includes(s.id))
    visibleSubs = isChatDept ? visibleSubs : visibleSubs.filter(s => !s.name.startsWith('Mobilt Bredbånd'))

    return (
      <div>
        <h1>Velg abonnement</h1>
        {visibleSubs.sort((a, b) => a.amount > b.amount ? 1 : -1).map(sub => {
          const priceStr = prepaidSubscriptionIds.includes(sub.id) ? '' : ` (${sub.amount || ''}/mnd)`
          return (
            <div className="input_wrapper" key={`subscription-${sub.id}`}>
              <input type="radio" value={sub.id} id={`subscription-${sub.id}`} checked={sub.id === item.subscriptionId} onClick={e => this.props.changeSubscription(e.target.value)} onChange={e => { }} />
              <label htmlFor={`subscription-${sub.id}`}><span>{`${sub.name}${priceStr}`}</span></label>
              {sub.kidSafe && sub.id === item.subscriptionId &&
                <div className="callout">
                  <div className="input_wrapper" >
                    <label htmlFor="childs-name">Barnets navn:</label>
                    <input type="text" id="childs-name" value={item.childsName} onChange={e => dispatch(setFormData({ childsName: e.target.value }))} />
                  </div>
                  <div className="input_wrapper" >
                    <label htmlFor="date-of-birth">Barnets fødselsdato:</label>
                    <DatePicker
                      dateFormat="dd/MM/yyyy"
                      className="form-control"
                      selected={item.childsDateOfBirth ? moment(item.childsDateOfBirth).toDate() : moment().toDate()}
                      onChange={date => dispatch(setFormData({ childsDateOfBirth: date }))} />
                  </div>
                </div>
              }
            </div>
          )
        }
        )}
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    formData: state.formData,
    layout: state.layout,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscriptionsComponent);
