import { setHome } from './actions/layout'

export const setRoute = ({store}) => {
  const path = window.location.hash.split('/')
  const rxuuid = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/

  // set property detail if url matches
  if (path[1] === 'invite') {
    if (path[2].match(rxuuid)) {
      store.dispatch(setHome({route: `/invite/${path[2]}`}))
    }
  }
  // always fall back to home
  else {
    store.dispatch(setHome({route: '/'}))
  }
  // store.dispatch(setHome(window.location.hash))
}
