import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import moment from 'moment'

import config from '../../../../config'

import { setFormData } from '../../../../actions/formData'

class PowerItemsComponent extends Component {
  deleteItem = idx => {
    const { formData, dispatch } = this.props
    const { powerItems } = formData
    const newItems = powerItems.slice(0, idx).concat(powerItems.slice(idx + 1))
    dispatch(setFormData({powerItems: newItems}))
  };

  editItem = idx => {
    const { parent } = this.props
    parent.setState({showAddPowerModal: true, editIndex: idx})
  };

  render() {
    const { formData, dispatch, parent, powerPlans } = this.props
    const { powerItems } = formData
    return (
      <div className="items power">
        <h1>Strømabonnementer</h1>
        {Boolean(!powerItems.length) &&
          <div className="no-owner">
            <div>Ingen strømabonnementer definert.</div>
            <div className="buttons" style={{marginTop: '2rem'}}>
              <button onClick={() => parent.setState({showAddPowerModal: true, editIndex: null})}>Legg til strømabonnement</button>
            </div>
          </div>
        }
        {Boolean(powerItems.length) &&
          <div>
            <div className="items-list">
              {powerItems.map((item, i) => {
                const plan = powerPlans.find(s => s.id === item.planId)
                const bDate = item.dateOfBirth
                return (
                  <div key={`item-${i}`}>
                    <div className="main" onClick={() => this.editItem(i)}>
                      <div className="item">
                        <div className="sub">
                          {plan ? plan.name : ''}
                        </div>
                        <div className="price">
                          {plan ? plan.amount : ''} kr
                        </div>
                      </div>
                      <div className="person">
                        <div className="name">
                          {item.firstName} {item.lastName}
                        </div>
                        <div className="age">
                          {bDate ? moment().toDate().getFullYear() - moment(bDate).toDate().getFullYear() + ' år' : ''}
                        </div>
                      </div>
                    </div>
                    <button className="icon delete-item"  onClick={e => { e.stopPropagation(); this.deleteItem(i); }}></button>
                  </div>
                )
              })}
            </div>
            <div className="buttons" style={{marginTop: '2rem'}}>
              <button onClick={() => parent.setState({showAddPowerModal: true, editIndex: null})}>Legg til strømabonnement</button>
            </div>
          </div>
        }
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    formData: state.formData,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PowerItemsComponent)
