import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import moment from 'moment'

import config from '../../../../config'

import { getFamilySubscriptionTotalPrice } from '../../../../lib/prices'

import { setFormData } from '../../../../actions/formData'

const { chatDepartmentIds } = config

class PhoneItemsComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      checkPhoneNo: null,
      checkPhoneNoResults: null,
    }
  }

  deleteItem = idx => {
    const { formData, dispatch } = this.props
    const { phoneItems } = formData
    const newItems = phoneItems.slice(0, idx).concat(phoneItems.slice(idx + 1))
    dispatch(setFormData({ phoneItems: newItems }))
  };

  editItem = idx => {
    const { parent } = this.props
    parent.setState({ showAddPhoneModal: true, editIndex: idx })
  };

  editFamily = () => {
    const { parent } = this.props
    parent.setState({ showAddFamilyPhoneModal: true })
  }

  addForOne = () => {
    const { formData, dispatch, parent } = this.props
    parent.setState({ showAddPhoneModal: true, editIndex: null })
    dispatch(setFormData({ family: { ...formData.family, members: [] } }))
  }

  handleBulkDiscountExceptionChange = (checked, id) => {
    const { formData, dispatch } = this.props
    const bulkDiscountExceptions = formData.bulkDiscountExceptions || []
    const newBulkDiscountExceptions = checked
      ? bulkDiscountExceptions.filter(e => e !== id)
      : bulkDiscountExceptions.concat([id])
    dispatch(setFormData({ bulkDiscountExceptions: newBulkDiscountExceptions }))
  }

  checkPhoneNo = async ev => {
    ev.preventDefault()
    if (this.state.checkPhoneNo) {
      const phoneNo = this.state.checkPhoneNo.replace(/\s/gm, '')
      const response = await axios.post(`${config.newApiUrl}/rpc/quickstat`, { phone_no: phoneNo })
      this.setState({ checkPhoneNoResults: response?.data })
    }
  }

  toggleBulkInvoice = ev => {
    const { formData, dispatch } = this.props
    const newPhoneItems = ev.target.checked
      ? formData.phoneItems.map(phoneItem => {
        return config.unlimitedSubscriptionIds.includes(phoneItem.subscriptionId)
          ? { ...phoneItem, campaigns: phoneItem.campaigns.filter(c => c !== config.u30CampaignId) }
          : phoneItem
      })
      : formData.phoneItems
    dispatch(setFormData({ bulkInvoiceDiscountActive: ev.target.checked, phoneItems: newPhoneItems }))
  }

  render() {
    const { appData, formData, layout, dispatch, parent, subscriptions } = this.props
    const { phoneItems, family } = formData
    const { departmentId } = layout.data
    const isUkraineFlow = departmentId === config.ukraineDepartmentId
    const bulkDiscountExceptions = formData.bulkDiscountExceptions || []


    // Check if there is at least one unlimited subscription and one U30 campaign
    let hasAtLeastOneUnlimitedSub = false
    let hasAtLeastOneU30Campaign = false
    let bulkInvoiceAllowed = true
    formData.phoneItems.forEach(phoneItem => {
      if (config.unlimitedSubscriptionIds.includes(phoneItem.subscriptionId)) {
        hasAtLeastOneUnlimitedSub = true
      }
      if (phoneItem.campaigns.includes(config.u30CampaignId)) {
        hasAtLeastOneU30Campaign = true
      }
    })

    // Disallow bulk invoice if there is at least one unlimited subscription and one U30 campaign
    if (hasAtLeastOneUnlimitedSub && hasAtLeastOneU30Campaign) {
      bulkInvoiceAllowed = false
    }

    return (
      <div className="items">
        <h1>Telefonabonnementer</h1>
        {Boolean(!phoneItems.length) && Boolean(!family.completed) && !isUkraineFlow &&
          <div className="no-owner">
            <div className="buttons" style={{ marginTop: '2rem' }}>
              <div className="add-button-container" onClick={() => this.addForOne()}>
                <div>
                  <i className="fa-icon fas fa-user"></i>
                </div>
                <div>
                  <button>Enkeltabonnement(er)</button>
                </div>
              </div>
              <div className="or-container">eller</div>
              <div className="add-button-container" onClick={() => parent.setState({ showAddFamilyPhoneModal: true, editIndex: null })}>
                <div>
                  <i className="fa-icon fas fa-users"></i>
                </div>
                <div>
                  <button>Familieabonnement</button>
                </div>
              </div>
            </div>
          </div>
        }
        {Boolean(!phoneItems.length) && Boolean(!family.completed) && isUkraineFlow &&
          <div className="no-owner ukraine">
            <div className="buttons" style={{ marginTop: '2rem' }}>
              <div className="add-button-container" onClick={() => this.addForOne()}>
                <div>
                  <i className="fa-icon fas fa-user"></i>
                </div>
                <div>
                  <button>Bestill Ukraina-abonnement</button>
                </div>
              </div>
            </div>
          </div>
        }
        {family.completed &&
          <div>
            <div className="items-list">
              <div className="item owner-item">
                <div className="main" onClick={this.editFamily}>
                  <div className="item">
                    <div className="sub">
                      Familie
                    </div>
                    <div className="price">
                      {getFamilySubscriptionTotalPrice(family, (appData.familyDataPackages.find(p => p.id === formData.family.selectedPackageId) || {}))} kr/mnd
                    </div>
                  </div>
                  <div className="person">
                    <div className="name">
                      {formData.family.counts.adult + formData.family.counts.child} familiemedlemmer
                    </div>
                    <div className="age">
                      {((appData.familyDataPackages.find(p => p.id === formData.family.selectedPackageId) || {}).name || '').replace(/[^0-9]/g, '')} GB felles data
                    </div>
                  </div>
                </div>
                <button className="icon edit-item" onClick={this.editFamily}></button>
              </div>
            </div>
          </div>
        }
        {Boolean(phoneItems.length) &&
          <div>
            <div className="items-list">
              {phoneItems.map((item, i) => {
                const isOwner = i === 0 && !formData.singleExistingOwnerPhoneNo
                const sub = subscriptions.find(s => s.id === item.subscriptionId)
                const bDate = item.dateOfBirth
                return (
                  <div key={`item-${i}`} className={`item ${i === 0 ? 'owner-item' : ''}`}>
                    <div className="main-wrapper">
                      <div className="main" onClick={() => this.editItem(i)}>
                        <div className="item">
                          {isOwner &&
                            <div className="owner-label">Eier av abonnementet</div>
                          }
                          <div className="sub">
                            {sub ? sub.name : ''}
                          </div>
                          {Boolean(item.campaigns.length) &&
                            <div className="campaigns">
                              {sub ? item.campaigns.map(c => appData.campaigns.find(cm => cm.id === c).name).join(', ') : ''}
                            </div>
                          }
                          <div className="price">
                            {sub ? sub.amount : ''} kr
                          </div>
                        </div>
                        <div className="person">
                          <div className="name">
                            {item.firstName} {item.lastName}
                          </div>
                          <div className="age">
                            {bDate ? moment().toDate().getFullYear() - moment(bDate).toDate().getFullYear() + ' år' : ''}
                          </div>
                        </div>
                      </div>
                      {!isOwner &&
                        <button className="icon delete-item" onClick={e => { e.stopPropagation(); this.deleteItem(i); }}></button>
                      }
                    </div>
                    {false && formData.bulkInvoiceDiscountActive && chatDepartmentIds.includes(departmentId) &&
                      <div className="secondary-wrapper">
                        <div className="input_wrapper">
                          <input type="checkbox" id={`remove-from-bulk-discount-${i}`} checked={!bulkDiscountExceptions.includes(item._id)} onChange={ev => this.handleBulkDiscountExceptionChange(ev.target.checked, item._id)} />
                          <label htmlFor={`remove-from-bulk-discount-${i}`}>
                            <span>Inkludert i samlerabatt</span>
                          </label>
                        </div>
                      </div>
                    }

                  </div>
                )
              })}

            </div>

            {!isUkraineFlow &&
              <div className="buttons" style={{ marginTop: '2rem' }}>
                <button onClick={() => parent.setState({ showAddPhoneModal: true, editIndex: null })}>Legg til bruker</button>
              </div>
            }

            {false && formData.phoneItems.length > 1 &&
              <div className="shared-invoice-options">
                <div className="intro">Når man bestiller flere enkeltabonnementer samtidig blir det automatisk samlefaktura.</div>
                {bulkInvoiceAllowed &&
                  <div className="input_wrapper">
                    <input id="bulk-invoice-toggle" type="checkbox" checked={formData.bulkInvoiceDiscountActive || false} onChange={this.toggleBulkInvoice} />
                    <label htmlFor="bulk-invoice-toggle"><span>Skal Samlerabatt også aktiveres?</span></label>
                    {!hasAtLeastOneU30Campaign &&
                      <div style={{ marginTop: '0.5rem' }}>Merk at Under 30-kampanjen vil bli fjernet fra alle Ubegrenset-abonnementer hvis Samlerabatt aktiveres.</div>
                    }
                  </div>
                }
                {!bulkInvoiceAllowed &&
                  <div className="bulk-invoice-info">
                    <div>Samlerabatt er ikke mulig å velge fordi Under 30-kampanjen er valgt for minst ett av abonnementene, samt at minst ett av abonnementene er Ubegrenset.</div>
                  </div>
                }
              </div>
            }
          </div>
        }
        {chatDepartmentIds.includes(departmentId) &&
          <div className="check-phone-number">
            <h3>Sjekk Talkmore-status for mobilnummer</h3>
            <form onSubmit={this.checkPhoneNo}>
              <div className="input-and-button">
                <input placeholder="Mobilnummer" value={this.state.checkPhoneNo} onChange={ev => this.setState({ checkPhoneNoResults: null, checkPhoneNo: ev.target.value })} />
                <button disabled={!this.state.checkPhoneNo || this.state.checkPhoneNo.length < 8} type="submit">Sjekk nummer</button>
              </div>
            </form>
            {this.state.checkPhoneNoResults &&
              <div className="results">
                {this.state.checkPhoneNoResults.mobile && !this.state.checkPhoneNoResults.electricity &&
                  <div className="mobile">{this.state.checkPhoneNo} er registrert som <strong>mobilkunde</strong> hos Talkmore.</div>
                }
                {this.state.checkPhoneNoResults.electricity && !this.state.checkPhoneNoResults.mobile &&
                  <div className="power">{this.state.checkPhoneNo} er registrert som <strong>strømkunde</strong> hos Talkmore.</div>
                }
                {this.state.checkPhoneNoResults.mobile && this.state.checkPhoneNoResults.electricity &&
                  <div className="both">{this.state.checkPhoneNo} er registrert som både <strong>mobilkunde og strømkunde</strong> hos Talkmore.</div>
                }
                {!(this.state.checkPhoneNoResults.mobile || this.state.checkPhoneNoResults.electricity) &&
                  <div className="none">{this.state.checkPhoneNo} er <strong>ikke registrert</strong> som kunde hos Talkmore.</div>
                }
              </div>
            }
          </div>
        }
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    formData: state.formData,
    appData: state.appData,
    layout: state.layout,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PhoneItemsComponent)
