import React, { Component } from 'react'
import { connect } from 'react-redux'
// import SignaturePad from 'react-signature-pad'
import SignatureCanvas from 'react-signature-canvas'
import { toastr } from 'react-redux-toastr'
import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'
import $ from 'jquery'

import SummaryFooter from '../SummaryFooter'
import EditPhoneItem from '../AddSubscriptions/EditPhoneItem'

import config from '../../../config'

import { setFormData } from '../../../actions/formData'
import { randStr } from '../../../lib/utils'
import {
  getOrderPriceBreakdown,
} from '../../../lib/prices'

// Subscription IDs on the left, campaign IDs (additional data) on the right
const familyCampaignMappings = {
  // 4GB + 4GB
  'e9b00102-5367-477d-9b69-32f7552900b5': '10b0f48c-c9b0-49bb-937e-61f2dbf98319',
  // 8GB + 4GB
  'a4215d2a-a3fa-4c39-8815-3d9b0ad1c785': '342fa53e-86c3-4608-a27c-e416d69377d8',
  // 15GB + 4GB
  '9779031a-d548-467d-9971-3a202704b199': '342fa53e-86c3-4608-a27c-e416d69377d8',
  // 35GB + 5GB
  '5ddff8d5-5e4b-4244-b847-6a39681933a3': 'b5cc9f3a-6e81-4d4d-ae18-238d956e8bd2',
  // 60GB + 10GB
  '2f36e1af-3d58-40bd-a6af-b2ac52950331': '4f7013c5-a551-4611-a4a1-ae151694abc1',
  // // 4GB + 4GB
  // '6f9924b0-4b08-4894-a40e-1364455f1fdc': '10b0f48c-c9b0-49bb-937e-61f2dbf98319',
  // // 8GB + 4GB
  // '53239788-f739-451b-83e2-552fe8c5b0cd': '342fa53e-86c3-4608-a27c-e416d69377d8',
  // // 15GB + 4GB
  // '3f016529-b59b-4715-841d-36fa45dd1817': '342fa53e-86c3-4608-a27c-e416d69377d8',
  // // 35GB + 5GB
  // 'f4ca21a9-b9be-4225-9752-cccbbb50bcc3': 'b5cc9f3a-6e81-4d4d-ae18-238d956e8bd2',
  // // 60GB + 10GB
  // 'a978e464-9696-431f-8adf-00c2c07f7def': '4f7013c5-a551-4611-a4a1-ae151694abc1',
  // // 3GB + 2GB
  // '8ba7fb72-3e7f-4ffe-aa76-4401923fd81d': '10b0f48c-c9b0-49bb-937e-61f2dbf98319',
  // // 7GB + 4GB
  // '10f5ea32-f747-4e39-8ab9-887df5e14d60': '342fa53e-86c3-4608-a27c-e416d69377d8',
  // // 15GB + 4GB
  // 'd6938e6a-e0a4-4e6f-859c-cfa5c2f5b977': '342fa53e-86c3-4608-a27c-e416d69377d8',
  // // 20GB + 5GB
  // 'ffae7a57-886d-4042-bc90-d6123d2bd4be': 'b5cc9f3a-6e81-4d4d-ae18-238d956e8bd2',
  // // 35GB + 5GB
  // 'af30d9bf-0f8f-4953-b747-c8fb15d75011': 'b5cc9f3a-6e81-4d4d-ae18-238d956e8bd2',
  // // 60GB + 10GB
  // 'cc1b91db-ae3c-4058-af2b-18bd21156f42': '4f7013c5-a551-4611-a4a1-ae151694abc1',
}

function checkboxes(isUkraineFlow) {
  return isUkraineFlow
    ? [{
      id: 'terms',
      context: 'shared',
      value: 'Jeg godkjenner Talkmores generelle vilkår (https://talkmore.no/vilkar)',
      markup: <span>Jeg godkjenner Talkmores generelle vilkår (<a href="https://talkmore.no/vilkar" target="_blank">https://talkmore.no/vilkar</a>)</span>,
    }]
    : [{
      id: 'break-fee',
      context: 'mobile',
      value: 'Jeg er kjent med at Talkmore ikke dekker eventuelle bruddgebyr', // This is getting passed into the database
      markup: <span>Jeg er kjent med at Talkmore ikke dekker eventuelle bruddgebyr</span> // this is what's being displayed on the page (markup because of link in one)
    },
    // {
    //   id: 'time',
    //   value: 'Jeg er kjent med at kampanjeprisen er tidsbegrenset',
    //   markup: <span>Jeg er kjent med at kampanjeprisen er tidsbegrenset</span>,
    // },
    {
      id: 'terms',
      context: 'shared',
      value: 'Jeg godkjenner Talkmores generelle vilkår (https://talkmore.no/vilkar)',
      markup: <span>Jeg godkjenner Talkmores generelle vilkår (<a href="https://talkmore.no/vilkar" target="_blank">https://talkmore.no/vilkar</a>)</span>,
    }, {
      id: 'invoice-information',
      context: 'shared',
      value: ' Jeg har fått informasjon om hvordan første faktura vil se ut',
      markup: <span>Jeg har fått informasjon om hvordan første faktura vil se ut</span>,
    }, {
      id: 'reply-sms-needed',
      context: 'mobile',
      value: 'Jeg er informert om at et eventuelt bytte av juridisk eier av aktuelt mobiltelefonnummer forutsetter at jeg aktivt svarer bekreftende på mottatt SMS fra Talkmore',
      markup: <span>Jeg er informert om at et eventuelt bytte av juridisk eier av aktuelt mobiltelefonnummer forutsetter at jeg aktivt svarer bekreftende på mottatt SMS fra Talkmore</span>,
    }, {
      id: 'owner-premission',
      context: 'mobile',
      value: 'Jeg bekrefter at jeg er eier av mobilnummeret, eller med eiers tillatelse ønsker å overta som juridisk eier og overføre det aktuelle nummeret til Talkmore',
      markup: <span>Jeg bekrefter at jeg er eier av mobilnummeret, eller med eiers tillatelse ønsker å overta som juridisk eier og overføre det aktuelle nummeret til Talkmore</span>,
    }, {
      id: 'credentials',
      context: 'shared',
      value: 'Jeg har identifisert meg, dvs vist frem legitimasjon (førerkort, bankkort eller liknende)',
      markup: <span>Jeg har identifisert meg, dvs vist frem legitimasjon (førerkort, bankkort eller liknende)</span>,
    }]
}

const SMS_POLL_TIMEOUT = 1000
let smsCheckCount = 0

function apiResponseError(e) {
  let heading = 'Kunne ikke fullføre kjøp'
  let explanation = `Dette skyldes mest sannsynlig en feil med systemet eller nettverket. Kontakt Stian på 90163108.`
  try {
    if (e.response && e.response.data && e.response.data.errors) {
      const dataErr = e.response.data.errors[0]
      if (dataErr && dataErr.message) {
        explanation = dataErr.message
        switch (dataErr.code) {
          case 'export_failed':
            heading = 'Feilmelding fra Talkmore sitt system'
            break
          case 'validation_failed':
            heading = 'Innsendte verdier er ugyldige'
            if (dataErr.field) {
              explanation = dataErr.field + ' ' + explanation
            }
            break
          case 'system':
            heading = 'Det oppsto en systemfeil'
            break
          default:
            heading = 'Levelup-feil (kontakt Stian på 90163108)'
        }
      }
    }
    if (e.response && e.response.data && e.response.data.request_id) {
      explanation += ` [${e.response.data.request_id}]`
    }
  } catch (e) {
    if (e && e.message) {
      heading += ' (' + e.message + ')'
    }
  }
  return { heading, explanation }
}

function formItemToPayloadItem(formItem, overrides, isOwnerRecord = false) {
  overrides = overrides || {}
  formItem = {
    ...formItem,
    ...overrides,
  }

  const campaignsAsExtraServices = config.campaignsAsExtraServices.map(c => c[1])
  const realCampaigns = (formItem.campaigns || []).concat(formItem.extraServices.filter(e => campaignsAsExtraServices.includes(e)))
  const realExtraServices = formItem.extraServices.filter(e => !campaignsAsExtraServices.includes(e))

  return {
    is_owner: formItem.isOwner,
    first_name: formItem.firstName,
    last_name: formItem.lastName,
    email: formItem.email || 'reg@talkmore.no',
    phone: isOwnerRecord ? null : (formItem.purchaseMethod === 'port' ? formItem.phone : null),
    ssn: formItem.ssn || moment(formItem.dateOfBirth).format('DDMMYY'),
    gender: formItem.gender,
    date_of_birth: formItem.dateOfBirth,
    address: formItem.address,
    postal_code: formItem.postalCode,
    postal_area: formItem.postalArea,
    subscription_id: formItem.subscriptionId,
    campaigns: [...new Set(realCampaigns || [])],
    product_options: [...new Set(realExtraServices || [])],
    porting_date: isOwnerRecord ? null : formItem.portingDate,
    porting_time: isOwnerRecord ? null : formItem.portingTime || 5,
    sim_number: isOwnerRecord ? null : formItem.simNumber,
    sim_type: isOwnerRecord ? null : formItem.simMethod,
    recruited_by: formItem.recruitedBy,
  }
}

class SignAndSubmitComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      simError: false,
      subscription: null,
      ukraineVerified: false,
      editingItem: null,
      editIndex: null,
    }
  }

  componentDidMount() {
    const { formData } = this.props
    if (!formData.saleRef) {
      this.props.dispatch(setFormData({ saleRef: randStr(16) }))
    }
    if (formData.isWaitingForSMS) {
      this.checkSMSTimeout = setTimeout(this.checkSMSStatus, SMS_POLL_TIMEOUT)
    }

    window.addEventListener('resize', this.resizeCanvas)
    this.resizeCanvas()
    window.scrollTo(0, 0)
  }

  resizeCanvas = () => {
    const canvas = document.querySelector('canvas')

    if (canvas) {
      const signature = this.mySignature
      const ratio = Math.max(window.devicePixelRatio || 1, 1);
      canvas.width = canvas.offsetWidth * ratio;
      canvas.height = canvas.offsetHeight * ratio;
      canvas.getContext("2d").scale(ratio, ratio);
      signature.clear(); // otherwise isEmpty() might return incorrect value

      var parentWidth = $(canvas).parent().outerWidth()
      canvas.setAttribute("width", parentWidth)
      canvas.setAttribute("height", parentWidth * 0.5)
    }
  };

  saveImage = () => {
    const signature = this.mySignature
    if (!signature.isEmpty()) {
      const image = signature.toDataURL()
      this.props.dispatch(setFormData({ signature: image }))
    }
  };

  switchSignMethod = signMethod => {
    clearTimeout(this.checkSMSTimeout)
    this.props.dispatch(setFormData({ signMethod, isWaitingForSMS: false }))
  };

  getOwner = () => {
    const { formData } = this.props
    const { family } = formData
    console.log('family', family)
    if (family && family.members && family.members.length) {
      const { members } = family
      return members.find(m => m.owner)
    } else {
      return formData.phoneItems[0]
    }
  };

  getPackageName = (appData, formData, payload) => {
    let ret
    if (payload && payload.order && payload.order.items && payload.order.items.length) {
      const owner = payload.order.items.find(i => i.attributes.is_owner)
      // console.log('--> owner', owner)
      if (owner) {
        if (this.isFamilyOrder(formData)) {
          const packageExtraServiceId = owner.attributes.product_options.find(o => appData.familyDataPackages.find(p => p.id === o))
          // console.log('--> packageExtraServiceId', packageExtraServiceId)
          if (packageExtraServiceId) {
            const packageName = (appData.familyDataPackages.find(p => p.id === packageExtraServiceId) || {}).name
            if (packageName) {
              if (packageName.includes('Ubegrenset')) {
                ret = packageName
              } else {
                ret = packageName
                  .replace(/^[a-zA-Z -]+/, '')
                // .replace(/[^0-9]+/, '')
                // .replace(/ \+.*/, '')
              }
            }
          }
        } else {
          if (owner.attributes?.subscription_id) {
            ret = (appData.subscriptions.find(s => s.id === owner.attributes.subscription_id) || {}).name
          }
        }
      }
    }
    console.log('payload.order.items', payload.order.items)
    console.log('packageName', ret)
    return ret
  };

  initiateSMSVerification = () => {
    const { appData, formData } = this.props
    const { family } = formData
    const owner = this.getOwner() || {}
    const clientId = randStr(8)
    const payload = this.payload()
    const priceBreakdown = getOrderPriceBreakdown(appData, formData)
    const monthlyPrice = this.isFamilyOrder(formData)
      ? priceBreakdown.monthlyBreakdown.package.netAmount
      : priceBreakdown.monthlyBreakdown.netTotal
    const totalPrice = priceBreakdown.monthlyBreakdown.netTotal
    const memberPrice = totalPrice - monthlyPrice
    const perMemberPrice = memberPrice / family.members.length
    const data = {
      phone: owner.phone,
      client_id: clientId,
      state: { form_data: formData, order_payload: payload },
      sale_ref: formData.saleRef,
      monthly_cost: monthlyPrice,
      total_cost: totalPrice,
      member_price: memberPrice,
      per_member_price: perMemberPrice,
      package_name: this.getPackageName(appData, formData, payload),
      no_of_members: this.isFamilyOrder(formData) && family ? family.members.length : -1,
      type: this.isFamilyOrder(formData) ? 'family' : 'regular',
      is_test: formData.isTest || owner.email === 'test@talkmore.no',
    }
    axios.post(`${config.newApiUrl}/rpc/initiate_sms_verification`, data)
      .then(r => {
        console.log('reply from server', r)
        if (r.data.status === 'ok') {
          this.props.dispatch(setFormData({
            clientId: clientId,
            isWaitingForSMS: true,
            isSubmitting: false,
            serverId: r.data.server_id,
            verificationId: r.data.verification_id,
          }))
          this.checkSMSTimeout = setTimeout(this.checkSMSStatus, SMS_POLL_TIMEOUT)
        }
      })
      .catch(e => {
        toastr.error('Kunne ikke starte SMS-verifisering', `Vennligst prøv på nytt, eller kontakt...`)
        this.props.dispatch(setFormData({ isSubmitting: false, isWaitingForSMS: false }))
      })
  };

  checkSMSStatus = () => {
    clearTimeout(this.checkSMSTimeout)
    const { formData } = this.props
    const owner = this.getOwner() || {}
    const data = { phone: owner.phone, client_id: formData.clientId, server_id: formData.serverId }
    axios.post(`${config.newApiUrl}/rpc/check_sms_verification`, data)
      .then(r => {
        console.log('reply from server', r)
        if (r.data.status === 'ok') {
          clearTimeout(this.checkSMSTimeout)
          this.props.dispatch(setFormData({
            isWaitingForSMS: false,
            isSubmitting: false,
            smsVerified: true,
          }))
        } else if (r.data.status === 'pending') {
          this.checkSMSTimeout = setTimeout(this.checkSMSStatus, SMS_POLL_TIMEOUT)
        }
      })
      .catch(e => {
        console.log('error checking SMS status', e)
        // toastr.error('Kunne ikke starte SMS-verifisering', `Vennligst prøv på nytt, eller kontakt...`)
        this.checkSMSTimeout = setTimeout(this.checkSMSStatus, 5000)
      })
  };

  submit = (e) => {
    // this.props.dispatch(setFormData({isSubmitting: true}))
    // const data = Object.assign({}, this.props.formData)
    // data.departmentId = this.props.layout.data.departmentId
    // axios.post(`${config.apiUrl}/register-sale`, data)
    //   .then(r => {
    //     if (r.data.success) {
    //       this.props.dispatch(setFormData({step: 6, isSubmitting: false}))
    //     }
    //   })
    //   .catch(e => {
    //     toastr.error('Kunne ikke fullføre kjøp', `Vennligst prøv på nytt, eller kontakt...`)
    //     this.props.dispatch(setFormData({isSubmitting: false}))
    //   })

    const { formData } = this.props
    const { isSubmitting } = formData
    if (!isSubmitting) {
      this.props.dispatch(setFormData({ isSubmitting: true }))
      axios.post(`${config.newApiUrl}/orders`, this.payload())
        .then(r => {
          console.log('r.data', r.data)
          if (r.data.data && r.data.data.id) {
            this.props.dispatch(setFormData({ step: 'receipt', isSubmitting: false, importResult: r.data.data.import_result }))
          }
        })
        .catch(e => {
          console.log('e.response', e.response)
          const err = apiResponseError(e)
          toastr.error(err.heading, err.explanation)
          this.props.dispatch(setFormData({ isSubmitting: false }))
        })
    }
  };

  readyToVerify = () => {
    const { formData, layout } = this.props
    const { departmentId } = layout.data
    const isUkraineFlow = departmentId === config.ukraineDepartmentId
    return !(config.chatDepartmentIds.includes(departmentId) || formData.acceptBoxes.length === checkboxes(isUkraineFlow).length)
  };

  allowNext = () => {
    const { formData, layout } = this.props
    const { isSubmitting } = formData
    const { departmentId } = layout.data
    const isUkraineFlow = departmentId === config.ukraineDepartmentId
    if (isSubmitting) {
      return 'disabled'
    }
    if (config.isDebug) {
      return ''
    }
    if (((config.chatDepartmentIds.includes(departmentId) || (departmentId === config.ukraineDepartmentId && this.state.ukraineVerified)) || ((formData.signature || formData.smsVerified) &&
      formData.acceptBoxes.length === checkboxes(isUkraineFlow).length)) &&
      !(formData.isSubmitting || formData.isWaitingForSMS)) {
      return ''
    } else {
      return 'disabled'
    }
  };

  toggleAcceptBoxes = (e, currentArray) => {
    currentArray = currentArray ? currentArray : []
    if (e.target.checked) { // add extra service
      currentArray.push(e.target.value)
      return currentArray
    } else {
      currentArray = _.remove(currentArray, es => es !== e.target.value)
      // console.log('currentArray', currentArray)
      return currentArray
    }
  };

  isFamilyOrder = formData => {
    return Boolean(formData.family && formData.family.members && formData.family.members.length)
  };

  payload = () => {
    const { appData, formData, layout } = this.props
    const { departmentId } = layout.data
    const signMethod = config.chatDepartmentIds.includes(departmentId)
      ? 'none'
      : formData.signMethod
    const signValue = signMethod === 'sms'
      ? formData.clientId + '--' + formData.serverId
      : signMethod === 'none'
        ? '(exempted because chat dept.)'
        : formData.signature

    const rawOwnerItem = this.getOwner()
    const ownerItem = rawOwnerItem ? { ...rawOwnerItem } : null
    console.log('ownerItem', ownerItem)

    const isFamilyOrder = this.isFamilyOrder(formData)

    let owner
    let ownerMember
    let nonSharedOrderKeys

    if (isFamilyOrder) {
      // assume family subscription
      const { family } = formData
      const { members } = family
      const actualMembers = members.filter(m => !m.owner)
      // const dataPackage = appData.familyDataPackages.find(p => p.id === family.selectedPackageId)
      ownerItem.extraServices = ownerItem.extraServices || []
      ownerItem.campaigns = ownerItem.campaigns || []
      const ownerOverrides = {
        isOwner: true,
        subscriptionId: formData.family?.selectedPackageId,
      }
      // if (dataPackage) {
      //   ownerItem.extraServices.push(dataPackage.id)
      // }
      // Add extra GB campaign that matches the selected data package (if one exists)
      if ((!config.chatDepartmentIds.includes(departmentId)) || (config.chatDepartmentIds.includes(departmentId) && family.includeExtraData)) {
        const familyCampaignMapping = familyCampaignMappings[ownerOverrides.subscriptionId]
        if (familyCampaignMapping) {
          ownerOverrides.campaigns = ownerOverrides.campaigns || []
          ownerOverrides.campaigns.push(familyCampaignMapping)
        }
      }
      if (family.includeTenPercentOff) {
        ownerOverrides.campaigns = ownerOverrides.campaigns || []
        ownerOverrides.campaigns.push(config.familyTenPercentOffId)
      }
      // Add 10 GB welcome gift when sold by chat department
      if (config.chatDepartmentIds.includes(departmentId)) {
        ownerOverrides.campaigns = ownerOverrides.campaigns || []
        // ownerOverrides.campaigns.push(config.extra10GbId)
      }
      // // Add Buyout discount on owner if selected for entire family
      // if (formData.family.buyoutDiscount) {
      //   ownerOverrides.campaigns = ownerOverrides.campaigns || []
      //   ownerOverrides.campaigns.push(config.buyoutDiscountId)
      // }
      // Add Port discount on owner if selected for entire family
      if (formData.family.portDiscount) {
        ownerOverrides.campaigns = ownerOverrides.campaigns || []
        ownerOverrides.campaigns.push(config.portDiscountId)
      }
      // if (formData.family.recruitedBy) {
      //   ownerOverrides.recruitedBy = formData.family.recruitedBy
      // }
      if (family.ownerWantsSubscription) {
        const ownerMemberOverrides = {
          subscriptionId: config.sharedDataMemberPlan,
          // Move extra services from family sub to owner member
          extraServices: [...(ownerItem.extraServices || [])],
        }
        if (formData.family.recruitedBy) {
          ownerMemberOverrides.recruitedBy = formData.family.recruitedBy
        }

        // Remove extra services from family sub
        ownerItem.extraServices = []

        // Add Digital trygghet on owner if selected for entire family
        if (formData.family.digitalSafety) {
          ownerMemberOverrides.extraServices = ownerMemberOverrides.extraServices || []
          ownerMemberOverrides.extraServices.push(config.digitalSafetyId)
        }
        owner = {
          type: 'subscription',
          attributes: formItemToPayloadItem(ownerItem, ownerOverrides, true)
        }
        ownerMember = {
          type: 'subscription',
          attributes: formItemToPayloadItem(ownerItem, ownerMemberOverrides)
        }
      }
      const rest = actualMembers.map(rawMember => {
        const member = { ...rawMember }
        member.extraServices = member.extraServices || []
        const memberOverrides = {
          // subscriptionId: formData.family.selectedPackageId,
          subscriptionId: config.sharedDataMemberPlan,
        }
        if (member.childMode) {
          member.extraServices.push(config.fullControlId)
          member.extraServices.push(config.webProtectionId)
        }
        if (member.useOwnerAddress) {
          memberOverrides.address = ownerItem.address
          memberOverrides.postalCode = ownerItem.postalCode
          memberOverrides.postalArea = ownerItem.postalArea
        }
        if (formData.family.recruitedBy) {
          memberOverrides.recruitedBy = formData.family.recruitedBy
        }
        return {
          type: 'subscription',
          id: member._id,
          attributes: formItemToPayloadItem(member, memberOverrides)
        }
      })
      nonSharedOrderKeys = {
        items: family.ownerWantsSubscription ? [owner, ownerMember].concat(rest) : rest,
        phone_no_of_existing_account: family.ownerWantsSubscription ? '' : ownerItem.phone,
      }

    } else {
      owner = {
        type: 'subscription',
        id: ownerItem._id,
        attributes: formItemToPayloadItem(ownerItem, { isOwner: formData.singleExistingOwnerPhoneNo ? false : true })
      }
      const rest = formData.phoneItems.slice(1).map(i => {
        return {
          type: 'subscription',
          attributes: formItemToPayloadItem(i, {
            address: owner.attributes.address,
            postalCode: owner.attributes.postal_code,
            postalArea: owner.attributes.postal_area,
          })
        }
      })
      nonSharedOrderKeys = {
        items: [owner].concat(rest),
        phone_no_of_existing_account: formData.singleExistingOwnerPhoneNo || '',
      }

      if (formData.bulkInvoiceDiscountActive && nonSharedOrderKeys.items.length > 1) {
        const bulkDiscountExceptions = formData.bulkDiscountExceptions || []
        for (let it of nonSharedOrderKeys.items) {
          if (!bulkDiscountExceptions.includes(it.id)) {
            it.attributes.campaigns = (it.attributes.campaigns || []).concat([config.bulkInvoiceDiscountId])
          }
        }
      }
    }

    // THE SHARED PAYLOAD IS BELOW

    const sharedOrderKeys = {
      type: isFamilyOrder ? 'family' : 'regular',
      monthly_price: Math.round(getOrderPriceBreakdown(appData, formData).monthlyBreakdown?.netTotal || 0),
      checkboxes: formData.acceptBoxes,
      lookup_method: formData.lookupMethod,
      sign_method: signMethod,
      sign_value: signValue,
      position: {
        latitude: formData.latitude,
        longitude: formData.longitude,
      },
      seller: {
        department_id: this.props.layout.data.departmentId,
      },
      is_test: formData.isTest || (owner && owner.attributes.email === 'test@talkmore.no'),
    }

    return {
      order: {
        ...sharedOrderKeys,
        ...nonSharedOrderKeys,
      },
    }
  };

  saveEdits = edits => {
    const { appData, formData, layout, dispatch } = this.props
    const { phoneItems, family } = formData
    const item = phoneItems[this.state.editIndex]
    const newItem = { ...item, ...edits }
    const newItems = phoneItems.slice(0, this.state.editIndex).concat([newItem]).concat(phoneItems.slice(this.state.editIndex + 1))
    dispatch(setFormData({ phoneItems: newItems }))
    this.setState({ editIndex: null })
  };

  render() {
    const { appData, formData, dispatch, layout } = this.props
    // const { subscriptions } = appData
    const { isSubmitting } = formData
    const owner = this.getOwner() || {}
    const { departmentId } = layout.data
    const { chatDepartmentIds } = config
    const priceBreakdown = getOrderPriceBreakdown(appData, formData)
    console.log('priceBreakdown', priceBreakdown)
    // const monthlyPrice = priceBreakdown.monthlyBreakdown.package.netAmount
    const packageName = this.getPackageName(appData, formData, this.payload())
    console.log('packageName', packageName)
    // const isUkraineFlow = departmentId === config.ukraineDepartmentId

    return (
      <div className="flex-container">
        {this.state.editIndex !== null &&
          <div className="fieldsales-overlay">
            <EditPhoneItem editIndex={this.state.editIndex} cancel={() => this.setState({ editIndex: null })} save={edits => this.saveEdits(edits)} />
          </div>
        }
        <div className="content-bulk">
          <div className="content-bulk-inner">
            <div className="step__main">
              <div className="step__main__inner">
                {config.showingDebugInfo &&
                  <React.Fragment>
                    <div className="debug_element">
                      <h2>Position</h2>
                      <pre>
                        Latitude: {formData.latitude} {'\n'}
                        Longitude: {formData.longitude}
                      </pre>
                    </div>
                    <br />
                    <div className="debug_element">
                      <h2>Payload</h2>
                      <pre>{JSON.stringify(this.payload(), null, 2)}</pre>
                    </div>
                    <br />
                  </React.Fragment>
                }
                <div className="sub-summary">
                  <div className="heading-container">
                    <h1>Oppsummering av bestillingen</h1>
                  </div>
                  <div className="sub-summary-box">
                    <div className="monthly-price-text">
                      <div>Pris for valgt(e) abonnement(er) er <strong>kr. {Math.round(priceBreakdown.monthlyBreakdown.grossTotal)}/måned</strong> pluss eventuelle tilleggstjenester.</div>
                      {priceBreakdown.monthlyBreakdown.netTotal !== priceBreakdown.monthlyBreakdown.grossTotal &&
                        <div>Med fratrekk for rabatter blir månedspris <strong>kr. {Math.round(priceBreakdown.monthlyBreakdown.netTotal)}/måned</strong>.</div>
                      }
                    </div>
                  </div>
                </div>

                {!chatDepartmentIds.includes(departmentId) &&
                  <div>
                    <h1>Godkjenning av vilkår</h1>
                    {checkboxes().map(cb => (
                      <div className={`input_wrapper ${formData.isWaitingForSMS ? 'disabled' : ''}`} key={cb.id}>
                        <input type="checkbox"
                          value={cb.value}
                          id={cb.id}
                          disabled={formData.isWaitingForSMS}
                          checked={formData.acceptBoxes.indexOf(cb.value) === -1 ? false : true}
                          onChange={e => dispatch(setFormData({ acceptBoxes: this.toggleAcceptBoxes(e, formData.acceptBoxes) }))} />
                        <label htmlFor={cb.id}>{cb.markup}</label>
                      </div>
                    ))}
                    {/*
                    <h2>Vilkår for mobilabonnement</h2>
                    {checkboxes.slice(4, 6).map(cb => (
                      <div className={`input_wrapper ${formData.isWaitingForSMS ? 'disabled' : ''}`} key={cb.id}>
                        <input type="checkbox" 
                              value={cb.value} 
                              id={cb.id}
                              disabled={formData.isWaitingForSMS}
                              checked={formData.acceptBoxes.indexOf(cb.value) === -1 ? false : true}
                              onChange={e => dispatch(setFormData({acceptBoxes: this.toggleAcceptBoxes(e, formData.acceptBoxes)}))} />
                        <label htmlFor={cb.id}>{cb.markup}</label>
                      </div>
                    ))}
                    <h2>Vilkår for strømabonnement</h2>
                    {checkboxes.slice(2, 5).map(cb => (
                      <div className={`input_wrapper ${formData.isWaitingForSMS ? 'disabled' : ''}`} key={cb.id}>
                        <input type="checkbox" 
                              value={cb.value} 
                              id={cb.id}
                              disabled={formData.isWaitingForSMS}
                              checked={formData.acceptBoxes.indexOf(cb.value) === -1 ? false : true}
                              onChange={e => dispatch(setFormData({acceptBoxes: this.toggleAcceptBoxes(e, formData.acceptBoxes)}))} />
                        <label htmlFor={cb.id}>{cb.markup}</label>
                      </div>
                    ))}
                    */}
                  </div>
                }

                {(!(owner.phone || owner.portPhone) || formData.signMethod === 'signature') && !chatDepartmentIds.includes(departmentId) &&
                  <div>
                    <div className="heading-container">
                      <h1>Signatur</h1>
                      {(owner.phone || owner.portPhone) &&
                        <button className="link" onClick={() => this.switchSignMethod('sms')}>Bytt til SMS-signering</button>
                      }
                    </div>
                    <div className="input_wrapper">
                      {/*
                      <SignaturePad clearButton="true" ref="mySignature" />
                      <SignatureCanvas  ref={(ref) => { this.mySignature = ref }} penColor='green' canvasProps={{width: 500, height: 200, className: 'sigCanvas'}} />
                      */}
                      <div style={{ border: '2px solid #000e4e', height: '400px', marginBottom: '1rem' }}>
                        <SignatureCanvas ref={(ref) => { this.mySignature = ref }} canvasProps={{ style: { width: '100%', height: '100%' } }} />
                      </div>
                      <button className="save" onClick={e => this.saveImage()}>Lagre signatur</button>
                    </div>
                  </div>
                }
                {(owner.phone || formData.portPhone) && formData.signMethod === 'sms' && !chatDepartmentIds.includes(departmentId) &&
                  <div className="sign-confirm-container">
                    <div className="heading-container">
                      <h1>SMS-bekreftelse</h1>
                      {!formData.smsVerified &&
                        <button className="link" onClick={() => this.switchSignMethod('signature')}>Bytt til vanlig signering</button>
                      }
                    </div>
                    {(formData.isWaitingForSMS && !formData.smsVerified) &&
                      <div className="box">
                        <div>Venter på bekreftelse...</div>
                        <button onClick={e => this.initiateSMSVerification()}>Send SMS på nytt</button>
                      </div>
                    }
                    {(formData.smsVerified && !formData.isWaitingForSMS) &&
                      <div className="sms-verified">
                        Kunden har bekreftet bestillingen ved å svare OK på SMSen som ble sendt. Bestillingen kan nå fullføres.
                      </div>
                    }
                    {!(formData.isWaitingForSMS || formData.smsVerified) &&
                      <div className="box">
                        <div>Ved å trykke på knappen vil kunden få en SMS som må besvares med "OK" for å bekrefte bestillingen.</div>
                        <button disabled={this.readyToVerify()} onClick={e => this.initiateSMSVerification()}>Send SMS til {owner.phone || owner.portPhone}</button>
                      </div>
                    }
                  </div>
                }
                {config.isDebug &&
                  <div className="input_wrapper debug_element">
                    <input type="checkbox" id="is_test_sale" checked={formData.isTest || false} onChange={ev => dispatch(setFormData({ isTest: !formData.isTest }))} />
                    <label htmlFor="is_test_sale"><span>Testsalg</span></label>
                  </div>
                }
              </div>
            </div>
            <div className="step__footer_wrapper">
              <div className="step__footer">
                <button className="prev" onClick={e => {
                  dispatch(setFormData({ step: 'add-subs' }))
                }}>Forrige</button>
                <button className="submit" onClick={e => this.submit()} disabled={this.allowNext()}>{isSubmitting ? 'Vent litt...' : 'Send inn'}</button>
              </div>
            </div>
          </div>
        </div>
        <SummaryFooter setEditIndex={newEditIndex => this.setState({ editIndex: newEditIndex })} />
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  }
}

const mapStateToProps = state => {
  return {
    formData: state.formData,
    appData: state.appData,
    layout: state.layout
  }
}

const SignAndSubmit = connect(
  mapStateToProps,
  mapDispatchToProps
)(SignAndSubmitComponent);

export default SignAndSubmit
