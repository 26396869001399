import React from 'react'
import { connect } from 'react-redux'

import AddSubscriptions from './AddSubscriptions'
import SignAndSubmit from './SignAndSubmit'
import Receipt from './Receipt'

const StepsComponent = ({ formData }) => (
  <div className="main-flow-container">
    {formData.step === 'add-subs' && <AddSubscriptions />}
    {formData.step === 'sign-submit' && <SignAndSubmit />}
    {formData.step === 'receipt' && <Receipt />}
  </div>
)

const mapStateToProps = state => {
  return {
    formData: state.formData,
  }
}

const Steps = connect(
  mapStateToProps,
)(StepsComponent);

export default Steps
