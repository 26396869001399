import React, { Component } from 'react'
import { connect } from 'react-redux'

import ExtraAbroadCountry from './ExtraAbroadCountry'
import config from '../../../../../config'

class ExtraServicesComponent extends Component {
  render() {
    const { item, parent, appData, formData, dispatch, extraServices, toggleExtraService } = this.props
    const itemExtraServices = item.extraServices || []
    const filteredExtraServices = config.campaignsAsExtraServices.map(c => { return { id: c[1], name: c[0], active: true } })
      .concat(extraServices)
      .filter(s => s.active)

    let campaignMarkup
    if (item.subscriptionId) {
      campaignMarkup = <p style={{ marginBottom: 32 }}>Ingen tilgjengelige tilleggstjenester for abonnementet.</p>
    } else {
      campaignMarkup = <p style={{ marginBottom: 32 }}>Velg et abonnement.</p>
    }

    return (
      <div>
        <h1>Velg tilleggstjeneste(r)</h1>

        {filteredExtraServices.length > 0 ? (
          filteredExtraServices.map(extraService => (
            <React.Fragment key={`es-${extraService.id}`}>
              <div className="input_wrapper">
                <input type="checkbox"
                  value={extraService.id}
                  id={`es-${extraService.id}`}
                  checked={itemExtraServices.indexOf(extraService.id) !== -1}
                  onChange={e => {
                    const newExtraServices = toggleExtraService(extraService.id, e.target.checked, itemExtraServices)
                    const newItem = { ...item, extraServices: newExtraServices }
                    parent.setState({ item: newItem })
                  }} />
                <label htmlFor={`es-${extraService.id}`}><span>{extraService.name}</span></label>
              </div>
              <ExtraAbroadCountry item={item} extraService={extraService} />
            </React.Fragment>
          )
          )
        ) : (
          campaignMarkup
        )}
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  }
}

const mapStateToProps = state => {
  return {
    formData: state.formData,
    appData: state.appData,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExtraServicesComponent);
