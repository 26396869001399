import React from 'react'
import ReactDOM from 'react-dom'
import logger from 'redux-logger'
import { Provider } from 'react-redux'
import axios from 'axios'
import { createStore, applyMiddleware } from 'redux'
import ReduxToastr from 'react-redux-toastr'

// Redux
import Reducers from './reducers'

import App from './containers/app'
import { unregister } from './registerServiceWorker'

import { checkIfLoggedIn } from './auth';
import { setRoute } from './router'

import config from './config'

import './styles/main.scss'
import './styles/global-styles/index.scss'

window.process = {
  ...window.process,
};

const localStorer = store => next => action => {
  let result = next(action)
  const newState = store.getState()
  if (process && process.env && process.env.REACT_APP_USE_STORED_STATE) {
    try {
      if (action.type !== 'RESET_FORM_DATA') {
        window.localStorage.setItem('_state', JSON.stringify(newState))
      }
    } catch (e) {
      console.error('Unable to save state to local storage:', e)
    }
  }
  return result
}

let store = createStore(Reducers, applyMiddleware(logger, localStorer));

checkIfLoggedIn({ store: store });
setRoute({ store: store })

window.addEventListener('popstate', () => {
  setRoute({ store: store })
});

const triggerReload = () => {
  if (window.confirm("Du har ikke siste versjonen av feltsalgsverktøyet. Vil du laste inn siste versjon?")) {
    window.location.reload();
  }
}

const checkScript = () => {
  // check if js file is accessable
  const scripts = document.getElementsByTagName('script')
  const myScript = scripts[scripts.length - 1]
  axios.get(myScript.src).then(r => console.log('Script ok')).catch(e => triggerReload())
}

if (!config.isLocal) {
  // setInterval(() => {
  //   checkScript()
  // }, 5000)
}

ReactDOM.render(
  <Provider store={store}>
    <div className="app_wrapper">
      <App />
      <ReduxToastr
        timeOut={4000}
        newestOnTop={false}
        preventDuplicates
        position="bottom-right"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar />
    </div>
  </Provider>,
  document.getElementById('root'));
unregister();
