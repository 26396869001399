
import React, {Component} from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import axios from 'axios'
import moment from 'moment'
import validate from 'validate.js'
import DatePicker from 'react-datepicker'

import config from '../../../../config'

import { setFormData } from '../../../../actions/formData'

import PowerPlans from './PowerPlans'
// import Campaigns from './campaigns'
// import ExtraServices from './extra-services'
// import ExtraAbroadCountry from './extra-services/extra-abroad-country'

const constraints = {
  birthDate: {
    format: {
      pattern: /^([012][0-9]\.[01][0-9]\.[12][0-9][0-9][0-9])$/,
    },
    length: {
      is: 10
    }
  },
};

const defaultItem = {
}

class AddPowerItemComponent extends Component {
  constructor(props) {
    super(props)
    const { formData } = props
    const existingItem = typeof props.editIndex === 'number'
      ? formData.powerItems[props.editIndex]
      : null
    const dob = existingItem && existingItem.dateOfBirth ? moment(existingItem.dateOfBirth).format('DD.MM.YYYY') : ''
    this.state = {
      showAddOnModal: false,
      item: existingItem || defaultItem,
      dob: dob,
      errors: {
      },
      valid: {
      },
    }
  }

  allowSave = (formData) => {
    const { item } = this.state
    return Boolean(item.planId && item.firstName && item.lastName && item.email && item.dateOfBirth)
  };

  changePlan = planId => {
    // const { dispatch } = this.props
    // dispatch(setFormData({product: subscriptionId, campaigns: [], extraServices: []}))
    // this.getSubscriptionsExtraServices(subscriptionId)
    const item = {...this.state.item, planId}
    this.setState({item})
  };

  addItem = () => {
    const { formData, dispatch, parent, editIndex } = this.props
    let powerItems
    if (typeof editIndex === 'number') {
      powerItems = formData.powerItems.slice(0, editIndex).concat([this.state.item]).concat(formData.powerItems.slice(editIndex + 1))
    } else {
      powerItems = formData.powerItems.concat([this.state.item])
    }
    dispatch(setFormData({powerItems, newPowerSession: false}))
    parent.setState({showAddPowerModal: false})
  };

  close = () => {
    const { formData, dispatch, parent } = this.props
    dispatch(setFormData({newPowerSession: false}))
    parent.setState({showAddPowerModal: false})
  };

  render() {
    const { item, showAddOnModal } = this.state
    const { user, formData, dispatch, layout, powerPlans } = this.props
    const { departmentId } = layout.data

    return powerPlans.length > 0 ? (
      <div>
        <div>
          {config.showingDebugInfo  && 
            <div className="debug_element">
              <h2>Item</h2>
              <pre>{JSON.stringify(item, null, 2)}</pre>
            </div>
          }
          <PowerPlans item={item} plans={powerPlans} changePlan={this.changePlan} />

          {item.planId &&
            <div style={{marginBottom: '1rem'}}>
              <h1>Personalia for abonnent</h1>
              <div className="input_wrapper"><label htmlFor="firstName">Fornavn:</label>
                <input type="text" id="firstName" value={item.firstName || ''} onChange={e => { this.setState({item: {...item, firstName: e.target.value}}) } } />
              </div>
              <div className="input_wrapper"><label htmlFor="lastName">Etternavn:</label>
                <input type="text" id="lastName" value={item.lastName || ''} onChange={e => { this.setState({item: {...item, lastName: e.target.value}}) } } />
              </div>
              <div className="input_wrapper"><label htmlFor="email">Epost:</label>
                <input type="text" id="email" value={item.email || ''} onChange={e => { this.setState({item: {...item, email: e.target.value}}) } } />
              </div>
              <div className={`input_wrapper ${this.state.errors && this.state.errors.dateOfBirth ? 'error' : ''} ${this.state.valid && this.state.valid.dateOfBirth ? 'validated' : ''}`}><label htmlFor="dateOfBirth">Fødselsdato:</label>
                <input 
                  id="dateOfBirth"
                  placeholder="01.22.1974"
                  value={this.state.dob || ''}
                  onChange={e => {
                    const value = e.target.value
                    const dob = moment.utc(value, 'DD.MM.YYYY').toISOString()
                    this.setState({dob: value, item: {...item, dateOfBirth: dob}})
                  }}
                  onBlur={e => {
                    this.setState({errors: validate({dateOfBirth: item.dateOfBirth}, constraints)})
                  }}
                  onKeyUp={e => {
                    const value = e.target.value
                  
                    if (value.length === 10) {
                      this.setState({valid: {dateOfBirth: true}})
                      this.setState({errors: validate({dateOfBirth: item.dateOfBirth}, constraints)})
                    } else {
                      this.setState({valid: {dateOfBirth: true}})
                      this.setState({errors: validate({dateOfBirth: item.dateOfBirth}, constraints)})
                    }
                    const dob = moment.utc(value, 'DD.MM.YYYY').toISOString()
                    this.setState({dob: value, item: {...item, dateOfBirth: dob}})
                  }}
                />
              </div>

              <div style={{marginTop: '1rem', marginBottom: '1rem'}}>
                <h1>Strømmåler</h1>
                <div className="input_wrapper"><label htmlFor="email">Målepunkt-ID:</label>
                  <input type="text" id="powerMeterId" value={item.powerMeterId || ''} onChange={e => { this.setState({item: {...item, powerMeterId: e.target.value}}) } } />
                </div>
              </div>
            </div>
          }

          <div className="buttons">
            <button className="cancel" onClick={this.close}>Avbryt</button>
            <button className="continue" onClick={this.addItem} disabled={!this.allowSave()}>Lagre</button>
          </div>
        </div>
      </div>
    ) : <div />
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    formData: state.formData,
    layout: state.layout,
  }
}

const AddPowerItem = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPowerItemComponent)

export default AddPowerItem
