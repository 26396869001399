import React, { Component } from 'react'
import { connect } from 'react-redux'

import config from '../../../config'

import { setFormData } from '../../../actions/formData'
import { setAppData } from '../../../actions/appData'

class SummaryFooterComponent extends Component {
  constructor(props) {
    super(props)
    // const { formData, dispatch } = props
    this.state = {
    }
  }

  render() {
    const { appData, formData, layout, dispatch } = this.props
    const { phoneItems, family } = formData
    const { subscriptions } = appData
    // const { departmentId } = layout.data
    // const isUkraineFlow = departmentId === config.ukraineDepartmentId

    const editItem = index => {
      console.log('edit item')
      this.props.setEditIndex(index)
    }

    // Only for single, regular subs for now
    if (!(phoneItems && phoneItems.length)) {
      return false
    }

    // const { chatDepartmentIds } = config
    return (
      <div className="summary-footer">
        {
          /* 
          {Boolean(!phoneItems.length) && Boolean(!family.completed) && !isUkraineFlow &&
            <div>
              SUMMARY FOOTER
            </div>
          }
          {Boolean(!phoneItems.length) && Boolean(!family.completed) && isUkraineFlow &&
            <div>
              UKRAINE FOOTER
            </div>
          }
          {family.completed &&
            <div>
              FAMILY FOOTER
            </div>
          } 
          */
        }
        {Boolean(phoneItems.length) &&
          <div>
            {phoneItems.map((item, i) => {
              const isOwner = i === 0 && !formData.singleExistingOwnerPhoneNo
              const sub = subscriptions.find(s => s.id === item.subscriptionId)
              const bDate = item.dateOfBirth
              return (
                <div key={`item-${i}`} className={`item ${i === 0 ? 'owner-item' : ''}`} onClick={() => editItem(i)}>
                  <div className="info">
                    <div className="subscriber">{item.firstName} {item.lastName} (f. {bDate})</div>
                    <div className="email">{item.email}{item.email && item.phone ? ' · ' : ''}{item.phone}</div>
                    <div className="phone"></div>
                    <div className="subscription">{sub.name}</div>
                  </div>
                  <div className="controls">
                    <button className="icon edit-item"></button>
                  </div>
                </div>
              )
            })}
          </div>
        }
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  }
}

const mapStateToProps = state => {
  return {
    formData: state.formData,
    appData: state.appData,
    layout: state.layout,
  }
}

const SummaryFooter = connect(
  mapStateToProps,
  mapDispatchToProps
)(SummaryFooterComponent);

export default SummaryFooter
