export const setUser = user => {
  return {
    type: 'SET_USER',
    user: user
  };
}

export const unsetUser = user => {
  return {
    type: 'UNSET_USER',
  };
}