import React, {Component} from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import {toastr} from 'react-redux-toastr'

import config from '../../../config'
import { checkIfLoggedIn } from '../../../auth'

import { setHome } from '../../../actions/layout'

function removeQueryFromString (path) {
  return path.split("?")[0]
}

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}


class InviteForm extends Component {
  constructor(props){
    super(props)
    this.state = {
      email: '',
      password: '',
      repeatPassword: ''
    }
  }

  componentDidMount() {
    const email = getParameterByName('email')
    if (email){
      this.setState({email: email})
    }
  }

  resetPasswordAndLogIn = (e) => {
    e.preventDefault()
    const data = Object.assign({}, this.state)
    data.resetToken = removeQueryFromString(decodeURIComponent(this.props.resetToken))
    axios.post(`${config.apiUrl}/reset-password-and-log-in`, data)
      .then(r => {
        const myStorage = window.localStorage
        myStorage.setItem('token', `Bearer ${r.data.data.token}`)
        checkIfLoggedIn({dispatch: this.props.dispatch})
        this.props.dispatch(setHome())
      })
      .catch(e => {
        console.error('error: ', e)
        toastr.error('Kunne ikke logge inn', `Prøv igjen eller kontakt teamleader`)
      })
  };

  render() {
    return (
      <form onSubmit={e => this.resetPasswordAndLogIn(e)}>
        <h4>Sett nytt passord</h4>
        <div className="input_wrapper">
          <input type="text" placeholder="Epost" required value={this.state.email} disabled={true} onChange={e => {
            this.setState({email: e.target.value})
          }} />
        </div>
        <div className="input_wrapper">
          <input type="password" placeholder="Passord" required value={this.state.password} onChange={e => {
            this.setState({password: e.target.value})
          }} />
        </div>
        <div className="input_wrapper">
          <input type="password" placeholder="Gjenta passord" required value={this.state.repeatPassword} onChange={e => {
            this.setState({repeatPassword: e.target.value})
          }} />
        </div>
        <span>{this.state.error}</span>
        <button className="login">Lag bruker</button>
        <div><a href="" onClick={e => {
          e.preventDefault()
          this.props.setLogin()
        }}>Logg inn</a></div>
      </form>
    )
  }
}


const mapStateToProps = state => {
  return {
    anon: state.anon,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InviteForm)