import React from 'react'
import { connect } from 'react-redux'

import SelectFamilyPackage from './SelectFamilyPackage'
import FamilyOwner from './FamilyOwner'
import FamilyMembers from './FamilyMembers'

const FamilyStepsComponent = ({ formData, parent }) => (
  <div>
    {formData.familyStep === 'package' && <SelectFamilyPackage parent={parent} />}
    {formData.familyStep === 'owner' && <FamilyOwner parent={parent} />}
    {formData.familyStep === 'members' && <FamilyMembers parent={parent} />}
  </div>
)

const mapStateToProps = state => {
  return {
    formData: state.formData,
  }
}

const FamilySteps = connect(
  mapStateToProps,
)(FamilyStepsComponent);

export default FamilySteps
