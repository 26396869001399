import React, { Component } from 'react'
import axios from 'axios'
import { toastr } from 'react-redux-toastr'
import moment from 'moment'
import _ from 'lodash'

import config from '../config'
import { setModal, unsetModal } from '../actions/modal'
import { setFormData } from '../actions/formData'

export default function lookupPerson(type, data, mode) {
  if (type === 'dsf') {
    return getDSFPerson(this, data, mode)
  } else {
    return getDMPerson(this, data, mode)
  }
}

function getDSFPerson(component, data, mode) {
  const { ssn, firstName, lastName } = data

  const submitData = {
    ssn: ssn,
    firstname: firstName,
    lastname: lastName,
    foo: 'barX13+1',
  }

  if (!ssn || ssn.length < 10 || !firstName || firstName.length < 1 || !lastName || lastName.length < 1) {
    component.props.dispatch(setModal({
      show: true,
      heading: <h1>Både personnummer, fornavn og etternavn må oppgis for å kunne utføre kontroll mot folkeregisteret.</h1>,
      body: <p>Vennligst fyll ut dette.</p>,
      button: <button className="secondary large" onClick={() => {
        component.props.dispatch(unsetModal())
      }}>Ok</button>
    }))
  } else {
    axios.post(`${config.apiUrl}/get_dsf_person`, submitData)
      // axios.post(`${config.apiUrl}/get_person`, submitData)
      .then(r => {
        console.log('responseData', r.data.data)
        // XXX: The return value appears to be different in test and prod, hence all the logic below
        const responseData = (r.data && r.data.data) ? r.data.data : null
        // const deepData = data.data && data.data[0] ? data.data[0] : {ssn: data.dateOfBirth.replace(/-/g, '')}
        const address = typeof responseData.address === 'string' ? { street: responseData.address, zipCode: responseData.postalCode, city: responseData.postalArea } : (responseData.address || {})
        const hasAllRequired = responseData.firstName && responseData.lastName && address.street && address.zipCode && address.city
        const person = {
          verified: hasAllRequired,
          partlyVerified: !hasAllRequired,
          firstName: responseData.firstName + (responseData.middleName ? ` ${responseData.middleName}` : ''),
          lastName: responseData.lastName,
          email: data.email,
          ssn: submitData.ssn,
          address: address.street,
          postalCode: address.zipCode,
          postalArea: address.city,
          // dateOfBirth: moment.utc(deepData.ssn, 'YYYYMMDD').toISOString()
          dateOfBirth: moment.utc(responseData.dateOfBirth, 'YYYY-MM-DD').toISOString()
        }
        console.log('person', person)
        // component.props.dispatch(setFormData(person))
        const personKey = mode === 'family' ? 'owner' : 'item'
        component.setState({ [personKey]: { ...data, ...person } })
      })
      .catch(e => {
        console.log('e', e)
        if (e && e.response && e.response.data && e.response.data.errorCode === 'personNotFound') {
          component.props.dispatch(setModal({
            show: true,
            heading: <h1>Personen ble ikke funnet i folkeregisteret.</h1>,
            body: <div><p>Det betyr at vi ikke kan registrere abonnement på vedkommende.</p><p>Sjekk at personnummer, fornavn og etternavn er riktig.</p><p>Ta kontakt med kundeservice på tlf 915 09 915 for mer informasjon.</p><br /></div>,
            button: <button className="secondary large" onClick={() => {
              component.props.dispatch(unsetModal())
            }}>Lukk</button>
          }))
        } else {
          toastr.error('Det oppsto en feil', `Prøv på nytt eller kontakt teamleader`)
        }
      })
  }
}

function getDMPerson(component, data, mode) {
  const { formData } = component.props
  const { ssn, email, phone } = data

  const submitData = {
    ssn: ssn,
    phone: formData.currentPhone
  }

  if (!ssn || ssn.length < 10 || !phone || phone.length < 6) {
    component.props.dispatch(setModal({
      show: true,
      heading: <h1>Både telefonnr og personnr må oppgis for å kunne utføre kredittsjekk.</h1>,
      body: <p>Vennligst fyll ut dette.</p>,
      button: <button className="secondary large" onClick={() => {
        component.props.dispatch(unsetModal())
      }}>Ok</button>
    }))
  } else {
    if (email && email.length > 0) {
      submitData.email = email
    }

    axios.post(`${config.apiUrl}/get_dm_person`, submitData)
      .then(r => {
        if (r.data.data) {
          const responseData = r.data.data
          console.log(responseData)
          // set date of birth on data from get_persons
          let dateOfBirth = null
          if (_.has(responseData.data[0], 'appInfo.memberValues.item')) {
            const dob = _.find(responseData.data[0].appInfo.memberValues.item, i => i.name === 'birthDate')
            const dobFormat = _.find(responseData.data[0].appInfo.memberValues.item, i => i.name === 'dateFormat')
            console.log(dob, dobFormat)
            dateOfBirth = moment.utc(dob.val, dobFormat.val.toUpperCase()).toISOString()
            console.log('dateOfBirth', dateOfBirth)
          }

          const person = {
            ssn: data.ssn,
          }
          // component.props.dispatch(setFormData({ssn: component.state.ssn}))
          if (dateOfBirth) {
            // component.props.dispatch(setFormData({dateOfBirth: dateOfBirth}))
            person.dateOfBirth = dateOfBirth
          }

          // if (r.data.data.score) {
          //   component.props.dispatch(setFormData({score: parseInt(r.data.data.score, 10)}))

          //   if ( (component.props.formData.score < 5 || component.props.formData.score > 100) && passableScores.indexOf(parseInt(component.props.formData.score)) === -1) {
          //     component.props.dispatch(setModal({
          //       show: true,
          //       heading: <h1>Personen ble dessverre ikke kredittgodkjent.</h1>,
          //       body: <div><p>Det betyr at vi ikke kan registrere abonnement på vedkomne.</p><p>Ta kontakt med kundeservice på tlf 915 09 915 for mer informasjon.</p></div>,
          //       button: <button className="secondary clear large" onClick={() => {
          //         component.props.dispatch(resetFormData())
          //         component.props.dispatch(unsetModal())
          //       }}>Start forfra</button>
          //     }))
          //   }
          // }
          if (responseData.address) {
            person.address = responseData.address.street ? component.capitalizeFirstLetter(responseData.address.street) : ''
            person.postalCode = responseData.address.zipCode ? component.capitalizeFirstLetter(responseData.address.zipCode) : ''
            person.postalArea = responseData.address.city ? component.capitalizeFirstLetter(responseData.address.city) : ''
          }
          if (responseData.firstName) {
            person.firstName = component.capitalizeFirstLetter(responseData.firstName)
            if (responseData.middleName) {
              person.firstName += ' ' + component.capitalizeFirstLetter(responseData.middleName)
            }
          }
          if (responseData.lastName) {
            person.lastName = component.capitalizeFirstLetter(responseData.lastName)
          }

          const hasAllRequired = person.firstName && person.lastName && person.street && person.zipCode && person.city
          person.verified = hasAllRequired
          person.partlyVerified = !hasAllRequired

          const personKey = mode === 'family' ? 'owner' : 'item'
          component.setState({ [personKey]: { ...data, ...person } })
        }
      })
      .catch(e => {
        toastr.error('Kunne ikke finne person', `Prøv på nytt eller kontakt teamleader`)
      })
  }
}
