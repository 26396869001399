import React, {Component, Fragment} from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import {toastr} from 'react-redux-toastr'
import _ from 'lodash'

import config from '../../../config'

import { setDepartment } from '../../../actions/layout'
import { setFormData } from '../../../actions/formData'

class PickDepartment extends Component {
  constructor(props){
    super(props)
    this.state = {
      departmentId: '',
      subDepartments: [],
    }
  }

  componentDidMount() {
    // get sub departments
    const { user } = this.props
    this.setState({departmentId: user.departments[0].id})

    _.forEach(user.departments, department => {
      axios.get(`${config.apiUrl}/departments/${department.id}/departments`)
        .then(r => {
          let subDepartments = [...this.state.subDepartments]
          subDepartments = subDepartments.concat(r.data)
          this.setState({subDepartments: subDepartments})
        })
        .catch(e => {
          console.error('error: ', e)
        })
    })
  }

  chooseDepartment = (e) => {
    e.preventDefault()
    this.props.dispatch(setFormData({departmentId: this.state.departmentId}))
    this.props.dispatch(setDepartment(this.state.departmentId))
  }

  render() {
    const { user } = this.props
    const { subDepartments } = this.state
    return (
      <form onSubmit={e => this.chooseDepartment(e)}>
        <div className="input_wrapper">
          <select onChange={e => this.setState({departmentId: e.target.value})} value={this.state.departmentId}>
            {user && user.departments && user.departments.map(department => (
              <React.Fragment key={`parent-${department.id}`}>
                <option value={department.id} >{department.name}</option>
                {subDepartments.map(subDepartment => <option value={subDepartment.id} key={`child-${subDepartment.id}`}>-- {subDepartment.name}</option>)}
              </React.Fragment>
            ))}
          </select>
        </div>
        <button>Velg avdeling</button>
      </form>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    anon: state.anon,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PickDepartment)