
import React, { Component } from 'react'
import { connect } from 'react-redux'

import FamilyMember from '../FamilyMember'

import { validateFamily } from '../../../../../lib/family_validation'
import config from '../../../../../config'
import { setFormData } from '../../../../../actions/formData'

class FamilyStep3Component extends Component {
  constructor(props) {
    super(props)

    this.state = {
      ownerSet: false,
      editing: false,
    }
  }

  allowNext = () => {
    const { formData } = this.props;

    return validateFamily(formData.family)
  };

  prevStep = () => {
    const { dispatch } = this.props
    dispatch(setFormData({ familyStep: 'owner' }))
  };

  finish = () => {
    const { formData, dispatch, parent } = this.props
    dispatch(setFormData({ newFamilyPhoneSession: false, familyStep: 'members', family: { ...formData.family, completed: true } }))
    parent.setState({ showAddFamilyPhoneModal: false })
  }

  toggleEditing = () => {
    this.setState({
      ...this.state,
      editing: !this.state.editing,
    })
  }

  render() {
    const { layout, formData, dispatch } = this.props
    const { departmentId } = layout.data
    const { chatDepartmentIds } = config
    const { members } = formData.family
    let duplicatePhoneNo = false
    for (let member of members) {
      if (member.phone) {
        const same = members.filter(m => m.phone === member.phone)
        if (same.length > 1) {
          duplicatePhoneNo = [same[0].phone]
          break
        }
      }
    }

    return (
      <div>
        <h1>Flokken</h1>
        <div>
          {members.map((item, i) => {
            return (
              <FamilyMember key={item.id} member={item} i={i} toggleEditing={this.toggleEditing} />
            )
          })}
        </div>

        <h1 className="space-above">Bestillingen</h1>
        <div className="toggles">
          <div className={`${formData.family.digitalSafety === true ? 'ok' : ''}`}>
            <div>
              <div>Digital trygghet</div>
              <div className="toggle-buttons">
                <div className={`oh-yeah ${formData.family.digitalSafety === true ? 'active' : ''}`} onClick={e => dispatch(setFormData({ family: { ...formData.family, digitalSafety: true } }))}>Ja takk!</div>
                <div className={`oh-noes ${formData.family.digitalSafety === false ? 'active' : ''}`} onClick={e => dispatch(setFormData({ family: { ...formData.family, digitalSafety: false } }))}>Nei takk</div>
              </div>
            </div>
          </div>
          {/* <div className="input_wrapper">
            <input type="checkbox"
              value={1}
              id="buyoutDiscount"
              checked={formData.family.buyoutDiscount}
              onChange={e => {
                dispatch(setFormData({ family: { ...formData.family, buyoutDiscount: !formData.family.buyoutDiscount } }))
              }} />
            <label htmlFor="buyoutDiscount"><span>Utkjøpsrabatt</span></label>
          </div> */}
          {/* <div className="input_wrapper">
            <input type="checkbox"
              value={1}
              id="portDiscount"
              checked={formData.family.portDiscount}
              onChange={e => {
                dispatch(setFormData({ family: { ...formData.family, portDiscount: !formData.family.portDiscount } }))
              }} />
            <label htmlFor="portDiscount"><span>Porteringsrabatt</span></label>
          </div> */}
        </div>
        {chatDepartmentIds.includes(departmentId) &&
          <div className="input_wrapper">
            <label htmlFor="recruited-by"><span>Rekruttert av</span></label>
            <input type="text" id="recruited-by" value={formData.family.recruitedBy || ''} onChange={
              e => dispatch(setFormData({ family: { ...formData.family, recruitedBy: e.target.value } }))
            } />
          </div>
        }

        {duplicatePhoneNo &&
          <div className="partly-verified-container">
            <div>Telefonnummeret {duplicatePhoneNo} er registrert flere ganger. Dette må korrigeres før du kan gå videre.</div>
          </div>
        }

        {!this.state.editing &&
          <div className="buttons">
            <button className="cancel" onClick={this.prevStep}>Tilbake</button>
            <button className="continue" disabled={!this.allowNext() || duplicatePhoneNo} onClick={this.finish}>Fullfør</button>
          </div>
        }
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    formData: state.formData,
    appData: state.appData,
    layout: state.layout,
  }
}

const FamilyStep3 = connect(
  mapStateToProps,
  mapDispatchToProps
)(FamilyStep3Component)

export default FamilyStep3
