import React, {Component} from 'react'
import { connect } from 'react-redux'

import { setFormData } from '../../../../../actions/formData'
import config from '../../../../../config'

import countries from '../../../../../lib/countries'

const EXTRA_ABROAD_ID = 'dbddf5ce-e6ab-4096-90e8-673c128cb5fa'

class ExtraAbroadCountry extends Component {
  render() {
    const { item, extraService, formData, dispatch } = this.props
    const { extraServices } = item
    return !(extraServices.indexOf(EXTRA_ABROAD_ID) !== -1 && extraService.id === EXTRA_ABROAD_ID) ? false : (
      <div className="input_wrapper extra-abroad-country">
        <p>Sjekk at landet du ønsker å ringe til finnes i Ekstra Utland tjenesten vår:</p>
        <div className="select_wrapper">
        <select value={formData.extraAbroadId || ""} onChange={
          e => {
            dispatch(setFormData({extraAbroadId: parseInt(e.target.value, 10)}))
          }
        }>
          <option value="" disabled>Velg et land</option>
          {countries.map(c => (
            <option key={c.id} value={c.id}>{c.name}</option>
          ))}
        </select>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  }
}

const mapStateToProps = state => {
  return {
    formData: state.formData,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExtraAbroadCountry);
