window.process = {
  ...window.process,
};

let storedState
if (process && process.env && process.env.REACT_APP_USE_STORED_STATE) {
  try {
    const fullState = window.localStorage.getItem('_state')
    if (fullState) {
      storedState = JSON.parse(fullState).layout
    }
  } catch (e) {
    console.error('Unable to set initial state from local storage:', e)
  }
}

const initialState = storedState || {}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_HOME': {
      let newState = {}
      if (action.route)
        newState = { ...state, route: action.route }
      if (action.tab)
        newState = { ...state, tab: action.tab }
      if (action.data)
        newState = { ...state, data: action.data }
      return { ...state, ...newState }
    }
    default:
      return state;
  }
}