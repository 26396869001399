import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import config from '../../config'

import validate from 'validate.js'

const operators = [{
  id: 1,
  name: 'Telenor',
  allowed: false,
}, {
  id: 2,
  name: 'Talkmore',
  allowed: false,
}, {
  id: 3,
  name: 'Telia',
  allowed: true,
}, {
  id: 4,
  name: 'ICE.net',
  allowed: true,
}, {
  id: 5,
  name: 'OneCall',
  allowed: true,
}, {
  id: 6,
  name: 'Komplett Mobil',
  allowed: true,
}, {
  id: 7,
  name: 'Fjordkraft',
  allowed: true,
}, {
  id: 8,
  name: 'Annen operatør',
  allowed: true,
}]

const constraints = {
  simNumber: {
    length: {
      is: 12
    }
  },
  ssn: {
    length: {
      is: 11
    }
  },
  birthDate: {
    format: {
      pattern: /^([012][0-9]\.[01][0-9]\.[12][0-9][0-9][0-9])$/,
    },
    length: {
      is: 10
    }
  },
};

class PortTypeComponent extends Component {
  constructor(props) {
    super(props)
    const { formData } = props
    this.state = {
      errors: {
        simNumber: false,
      },
      valid: {
        simNumber: false,
      },
      simError: false,
    }
  }

  doOperatorCheck = () => {
    const { person } = this.props

    if (person.purchaseMethod === 'new') {
      return true
    } else {
      const { operatorId } = person
      if (operatorId) {
        const operator = operators.find(o => o.id === operatorId)
        if (operator && operator.allowed) {
          return true
        }
      }
      return false
    }
  };

  doPreCheck = () => {
    const { person } = this.props

    if (person.purchaseMethod === 'new') {
      return true
    } else if (person.purchaseMethod === 'port') {
      return this.doOperatorCheck()
    }
    return false
  };


  render() {
    const { formData, component, person, parentObject, layout } = this.props

    const preCheckOK = this.doPreCheck()
    const operatorCheckOK = this.doOperatorCheck()

    const { departmentId } = layout.data
    const { chatDepartmentIds } = config

    let operator;
    const { operatorId } = person
    if (operatorId) {
      operator = operators.find(o => o.id === operatorId)
    }

    return !component ? false : (
      <div>
        <div className="input_wrapper more-spacing">
          <div className="input_wrapper">
            <input type="radio" value={true} id="new-number" checked={person.purchaseMethod === 'new'} onClick={e => {
              const newStateForNewNumber = { ...person, purchaseMethod: 'new', phone: '', portingDate: null, simNumber: '' }
              component.setState({ [parentObject]: newStateForNewNumber })
            }} onChange={e => { }} />
            <label htmlFor="new-number"><span>Ønsker nytt telefonnr.</span></label>
          </div>
          <div className="input_wrapper">
            <input type="radio" value={false} id="port-number" checked={person.purchaseMethod === 'port'} onClick={e => {
              component.setState({ [parentObject]: { ...person, purchaseMethod: 'port' } })
            }} onChange={e => { }} />
            <label htmlFor="port-number"><span>Portere eksisterende telefonnr</span></label>
          </div>

        </div>

        {
          person.purchaseMethod === 'port' &&
          <div>
            <br />
            <h1>Hvilken operatør har du i dag?</h1>
            {operators.map(opr => (
              <div className="input_wrapper" key={opr.id}>
                <input type="radio" name="operator" value={opr.id} id={`operator-${opr.name}`} checked={person.operatorId === opr.id} onChange={e => {
                  component.setState({ [parentObject]: { ...person, operatorId: parseInt(opr.id, 10) } })
                }} />
                <label htmlFor={`operator-${opr.name}`}><span>{opr.name}</span></label>
              </div>
            ))}
            {operator && operator.allowed &&
              <React.Fragment>
                <div className="header_wrapper">
                  <br />
                  <h1>Porteringstidspunkt</h1>
                  <p>Dersom du ikke velger porteringstidspunkt, er det standard porteringstid som er gjeldende (7 virkedager frem, kl. 0500).</p>
                </div>
                <div className="port-time-wrapper">
                  <div>
                    <div class="column-heading">Dato</div>
                    <div className="input_wrapper">
                      <DatePicker
                        dateFormat="dd/MM/yyyy"
                        className="form-control"
                        selected={person.portingDate ? moment(person.portingDate).toDate() : null}
                        minDate={this.state.valid.simNumber ? moment() : moment().add(8, 'days').toDate()}
                        onChange={date => { console.log(date); component.setState({ [parentObject]: { ...person, portingDate: date ? moment.utc(moment(date).startOf('day') + ((86400 * 1000) / 2)) : null } }); }} />
                    </div>
                  </div>
                  <div>
                    <div class="column-heading">Tidspunkt</div>
                    <div className="input_wrapper">
                      <select
                        className="port-time-selector"
                        disabled={!Boolean(person.portingDate)}
                        value={person.portingDate ? (person.portingTime || 5).toString() : 5}
                        onChange={ev => { const portTime = ev.target.value; console.log(portTime); component.setState({ [parentObject]: { ...person, portingTime: parseInt(portTime) } }); }}>
                        <option value="5">05:00</option>
                        <option value="6">06:00</option>
                        <option value="7">07:00</option>
                        <option value="8">08:00</option>
                        <option value="9">09:00</option>
                        <option value="10">10:00</option>
                        <option value="11">11:00</option>
                        <option value="12">12:00</option>
                        <option value="13">13:00</option>
                        <option value="14">14:00</option>
                        <option value="15">15:00</option>
                      </select>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            }
            {operator && !operator.allowed &&
              <div>
                <h2>Vi overfører normalt ikke eksisterende Talkmore eller Telenor-kunder</h2>
                <p>Eventuelle unntak må godkjennes av teamleder.</p>
              </div>
            }
            {preCheckOK && operatorCheckOK &&
              <React.Fragment>
                {person.purchaseMethod === 'port' &&
                  <div style={{ marginTop: '2rem' }}>
                    <h1>SIM-nummer</h1>
                    {/*!formData.phone && <div>Nb: Sim nummer kan kun fylles ut om tlf.nr er fyllt ut</div>*/}
                    <div className="input_wrapper">
                      <input type="radio" value={true} id="sim-auto" checked={person.simMethod === 'auto'} onClick={() => {
                        component.setState({ [parentObject]: { ...person, simMethod: 'auto', simNumber: '' } })
                      }} onChange={e => { }} />
                      <label htmlFor="sim-auto"><span>Talkmore sender nytt SIM-kort i posten</span></label>
                    </div>

                    {chatDepartmentIds.includes(departmentId) &&
                      <div className="input_wrapper">
                        <input type="radio" value={true} id="sim-pickup" checked={person.simMethod === 'pickup'} onClick={() => {
                          component.setState({ [parentObject]: { ...person, simMethod: 'pickup', simNumber: 'hentesim' } })
                        }} onChange={e => { }} />
                        <label htmlFor="sim-pickup"><span>Kunden henter SIM-kort selv</span></label>
                      </div>
                    }

                    <div className="input_wrapper">
                      <input type="radio" value={true} id="sim-input" checked={person.simMethod === 'input'} onClick={() => {
                        component.setState({ [parentObject]: { ...person, simMethod: 'input', simNumber: '' } })
                      }} onChange={e => { }} />
                      <label htmlFor="sim-input"><span>Skriv inn SIM-nummer som skal brukes</span></label>
                    </div>
                    {person.simMethod === 'input' &&
                      <span>SIM-nummer må være 12 tegn langt. Lar du feltet stå tomt sendes nytt SIM-kort i posten.</span>
                    }
                  </div>
                }

                {person.purchaseMethod === 'port' && person.simMethod === 'input' &&
                  <div className={`input_wrapper ${this.state.errors && this.state.errors.simNumber ? 'error' : ''} ${this.state.valid && this.state.valid.simNumber ? 'validated' : ''}`}>
                    <input type="text"
                      /*disabled={formData.phone ? '': 'disabled'}*/
                      placeholder="Fyll inn SIM-nummer"
                      value={person.simNumber || ''}
                      onChange={e => {
                        const value = e.target.value
                        component.setState({ [parentObject]: { ...person, simNumber: value } })
                      }}
                      onBlur={e => {
                        this.setState({ errors: validate({ simNumber: person.simNumber }, constraints) })
                      }}
                      onKeyUp={e => {
                        const value = e.target.value

                        if (value.length === 12) {
                          this.setState({ valid: { simNumber: true } })
                          this.setState({ errors: validate({ simNumber: person.simNumber }, constraints) })
                        } else {
                          this.setState({ valid: { simNumber: true } })
                          this.setState({ errors: validate({ simNumber: person.simNumber }, constraints) })
                        }

                        component.setState({ [parentObject]: { ...person, simNumber: value } })
                      }}
                    />
                  </div>
                }
              </React.Fragment>
            }
          </div>
        }
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    formData: state.formData,
    layout: state.layout,
  }
}

const PortType = connect(
  mapStateToProps,
  mapDispatchToProps
)(PortTypeComponent)

export default PortType
