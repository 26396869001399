import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import AddPhoneItem from './AddPhoneItem'
import AddFamilyPhoneItem from './AddFamilyPhoneItem'
import AddPowerItem from './AddPowerItem'
import PhoneItems from './PhoneItems'
import SummaryFooter from '../SummaryFooter'
import PowerItems from './PowerItems'

import config from '../../../config'

import { setFormData } from '../../../actions/formData'
import { setAppData } from '../../../actions/appData'
import { validateFamily } from '../../../lib/family_validation'
import { getOrderPriceBreakdown } from '../../../lib/prices'

class AddSubscriptionsComponent extends Component {
  constructor(props) {
    super(props)
    const { formData, dispatch } = props
    this.state = {
      referalPhone: '',
      quickstatReply: null,
      subscriptions: [],
      powerPlans: [],
      showAddPhoneModal: formData.newPhoneSession,
      showAddFamilyPhoneModal: formData.newFamilyPhoneSession,
      showAddPowerModal: formData.newPowerSession,
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0)
  }

  lookupReferalCode = async ev => {
    ev.preventDefault()
    const response = await axios.post(`${config.newApiUrl}/rpc/quickstat`, { phone_no: this.state.referalPhone })
    this.setState({ quickstatReply: response.data })
    console.log(response.data)
  }

  allowNext = () => {
    const { appData, formData } = this.props
    return formData.phoneItems.length || formData.powerItems.length || validateFamily(formData.family)
    // if (formData.simNumber.length === 12 || formData.simNumber.length == 0) {
    //   return true
    // }
    // return false
  };

  render() {
    const { appData, formData, layout, dispatch } = this.props
    const { subscriptions, powerPlans } = appData
    const { showAddPhoneModal, showAddFamilyPhoneModal, showAddPowerModal, editIndex } = this.state
    const { departmentId } = layout.data
    const { chatDepartmentIds } = config
    return (
      <div>
        {showAddPhoneModal &&
          <div className="fieldsales-overlay">
            <AddPhoneItem parent={this} editIndex={editIndex} subscriptions={subscriptions} />
          </div>
        }
        {showAddFamilyPhoneModal &&
          <div className="fieldsales-overlay">
            <AddFamilyPhoneItem parent={this} editIndex={editIndex} subscriptions={subscriptions} />
          </div>
        }
        {showAddPowerModal &&
          <div className="fieldsales-overlay">
            <AddPowerItem parent={this} editIndex={editIndex} powerPlans={powerPlans} />
          </div>
        }
        <div className="step__main">
          <div className="step__main__inner">
            <PhoneItems parent={this} subscriptions={subscriptions} />
            {/*
            <PowerItems parent={this} powerPlans={powerPlans} />
            */}
          </div>
        </div>
        {chatDepartmentIds.includes(departmentId) &&
          <div className="power-referal-lookup step__main__inner">
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 1 }}>
                <form onSubmit={this.lookupReferalCode}>
                  <div className="input_wrapper"><label htmlFor="referalPhone">Finn strøm-vervekode for et mobilnummer</label>
                    <input name="referalPhone" placeholder="Mobilnummer" value={this.state.referalPhone} onChange={ev => this.setState({ referalPhone: ev.target.value })} />
                  </div>
                  <button disabled={!(this.state.referalPhone && this.state.referalPhone.length === 8)} style={{ marginRight: '1rem' }}>Finn vervekode</button>
                  <a href="" onClick={ev => { ev.preventDefault(); this.setState({ referalPhone: '', quickstatReply: null }) }}>Nullstill</a>
                </form>
              </div>
              <div className="referal-reply">
                {Boolean(!this.state.quickstatReply) &&
                  <div className="placeholder">Svaret kommer her...</div>
                }
                {Boolean(this.state.quickstatReply) &&
                  <React.Fragment>
                    {Boolean(this.state.quickstatReply.electricity && this.state.quickstatReply.electricity.customerNumber) &&
                      <div className="success">
                        VERV{this.state.quickstatReply.electricity.customerNumber}
                      </div>
                    }
                    {Boolean(!(this.state.quickstatReply.electricity && this.state.quickstatReply.electricity.customerNumber)) &&
                      <div className="not-found">
                        Kunden er ikke strømkunde hos Talkmore.
                      </div>
                    }
                  </React.Fragment>
                }
              </div>
            </div>
          </div>
        }
        {config.showingDebugInfo && config.debugSize !== 'minimized' &&
          <div className="debug_element">
            <h2>Price breakdown</h2>
            <pre>{JSON.stringify(getOrderPriceBreakdown(appData, formData), null, 2)}</pre>
          </div>
        }
        <div className="step__footer_wrapper">
          <div className="step__footer">
            <button className="next" onClick={e => dispatch(setFormData({ step: 'sign-submit' }))} disabled={!this.allowNext()}>Videre til vilkår og innsending</button>
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  }
}

const mapStateToProps = state => {
  return {
    formData: state.formData,
    appData: state.appData,
    layout: state.layout,
  }
}

const AddSubscriptions = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddSubscriptionsComponent);

export default AddSubscriptions
