import React, { Component } from 'react'
import { connect } from 'react-redux'

import Logo from '../Logo'

import InviteForm from './InviteForm'
import ResetForm from './ResetForm'
import SignInForm from './SignInForm'
import PickDepartment from './PickDepartment'

class AnonComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      inviteToken: false,
      resettoken: false
    }
  }

  componentDidMount() {
    // check if invite
    const path = window.location.hash.split('/')
    const rxuuid = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/

    // set property detail if url matches
    if (path[1] === 'invite' && path[2]) {
      this.setState({ inviteToken: path[2] })
    }

    // set property detail if url matches
    if (path[1] === 'invite' && path[2]) {
      this.setState({ inviteToken: path[2] })
    } else if (path[1] === 'reset-password' && path[2]) {
      this.setState({ resetToken: path[2] })
    }
  }

  setLogin = () => {
    this.setState({ inviteToken: null, resetToken: null }, () => {
      window.location.hash = ''
    })
  };

  render() {
    const { inviteToken, resetToken } = this.state
    const { layout, user } = this.props
    return (
      <main className="anon">
        <div className="anon_inner">
          <div className="form_wrapper">
            <Logo />
            {!user && inviteToken && <InviteForm inviteToken={inviteToken} setLogin={this.setLogin} />}
            {!user && resetToken && <ResetForm resetToken={resetToken} setLogin={this.setLogin} />}
            {!user && !inviteToken && !resetToken && <SignInForm />}
            {user && user.user && <PickDepartment />}
          </div>
        </div>
      </main>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    anon: state.anon,
    layout: state.layout
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  }
}

const Anon = connect(
  mapStateToProps,
  mapDispatchToProps
)(AnonComponent);

export default Anon