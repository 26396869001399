export default [
  {
    "id": 68,
    "name": "Andorra"
  },
  {
    "id": 1,
    "name": "Belgia"
  },
  {
    "id": 2,
    "name": "Bulgaria"
  },
  {
    "id": 3,
    "name": "Estland"
  },
  {
    "id": 4,
    "name": "Frankrike"
  },
  {
    "id": 5,
    "name": "Hellas"
  },
  {
    "id": 6,
    "name": "Irland"
  },
  {
    "id": 7,
    "name": "Italia"
  },
  {
    "id": 8,
    "name": "Kroatia"
  },
  {
    "id": 9,
    "name": "Kypros"
  },
  {
    "id": 10,
    "name": "Latvia"
  },
  {
    "id": 11,
    "name": "Litauen"
  },
  {
    "id": 12,
    "name": "Luxembourg"
  },
  {
    "id": 13,
    "name": "Makedonia"
  },
  {
    "id": 14,
    "name": "Monaco"
  },
  {
    "id": 15,
    "name": "Nederland"
  },
  {
    "id": 16,
    "name": "Polen"
  },
  {
    "id": 17,
    "name": "Portugal"
  },
  {
    "id": 18,
    "name": "Romania"
  },
  {
    "id": 19,
    "name": "Russland"
  },
  {
    "id": 20,
    "name": "Slovakia"
  },
  {
    "id": 21,
    "name": "Slovenia"
  },
  {
    "id": 22,
    "name": "Spania"
  },
  {
    "id": 23,
    "name": "Storbritannia"
  },
  {
    "id": 24,
    "name": "Sveits"
  },
  {
    "id": 25,
    "name": "Tsjekkia"
  },
  {
    "id": 26,
    "name": "Tyrkia"
  },
  {
    "id": 27,
    "name": "Tyskland"
  },
  {
    "id": 28,
    "name": "Ungarn"
  },
  {
    "id": 29,
    "name": "Østerrike"
  },
  {
    "id": 30,
    "name": "Argentina"
  },
  {
    "id": 31,
    "name": "Australia"
  },
  {
    "id": 32,
    "name": "Bahamas"
  },
  {
    "id": 33,
    "name": "Bangladesh"
  },
  {
    "id": 34,
    "name": "Brasil"
  },
  {
    "id": 35,
    "name": "Canada"
  },
  {
    "id": 36,
    "name": "Colombia"
  },
  {
    "id": 37,
    "name": "Filippinene"
  },
  {
    "id": 38,
    "name": "Hong Kong"
  },
  {
    "id": 39,
    "name": "India"
  },
  {
    "id": 40,
    "name": "Indonesia"
  },
  {
    "id": 41,
    "name": "Irak"
  },
  {
    "id": 42,
    "name": "Iran"
  },
  {
    "id": 43,
    "name": "Israel"
  },
  {
    "id": 44,
    "name": "Japan"
  },
  {
    "id": 45,
    "name": "Jordan"
  },
  {
    "id": 46,
    "name": "Kambodsja"
  },
  {
    "id": 47,
    "name": "Kina"
  },
  {
    "id": 48,
    "name": "Martinique"
  },
  {
    "id": 49,
    "name": "Mexico"
  },
  {
    "id": 50,
    "name": "New Zealand"
  },
  {
    "id": 51,
    "name": "Pakistan"
  },
  {
    "id": 52,
    "name": "Puerto Rico"
  },
  {
    "id": 53,
    "name": "Singapore"
  },
  {
    "id": 54,
    "name": "Sør-Afrika"
  },
  {
    "id": 55,
    "name": "Sør-Korea"
  },
  {
    "id": 56,
    "name": "Taiwan"
  },
  {
    "id": 57,
    "name": "Thailand"
  },
  {
    "id": 58,
    "name": "Trinidad & Tobago"
  },
  {
    "id": 59,
    "name": "USA"
  },
  {
    "id": 60,
    "name": "Venezuela"
  },
  {
    "id": 61,
    "name": "Vietnam"
  },
  {
    "id": 62,
    "name": "Danmark"
  },
  {
    "id": 63,
    "name": "Finland"
  },
  {
    "id": 64,
    "name": "Færøyene"
  },
  {
    "id": 65,
    "name": "Grønland"
  },
  {
    "id": 66,
    "name": "Island"
  },
  {
    "id": 67,
    "name": "Sverige"
  }
];