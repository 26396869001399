import config from '../config'

const EXTRA_PRICE_PER_ADULT = 210
const EXTRA_PRICE_PER_CHILD = 100

const priceRules = {
  discounts: {
    // Under 30
    '9b9ac34b-1867-43aa-8a78-757cca77bed9': {
      percentage: 20,
      target: 'item',
    },
    // // Samlerabatt
    // '168b2c0a-9837-449a-9451-e4706322ebe5': {
    //   percentage: 10,
    //   // target: 'order',
    //   target: 'item',
    // },
    // Rabatt: Enkeltabonnement 10%
    'e2ef5786-3dda-47e0-982f-7a634fd4b056': {
      percentage: 10,
      // target: 'order',
      target: 'item',
    },
  },
}

const isFamily = formData => {
  return formData.family && formData.family.members && formData.family.members.length
}

export const getFamilySubscriptionTotalPrice = (family, pkg) => {
  return pkg.amount + (family.counts.adult * EXTRA_PRICE_PER_ADULT) + (family.counts.child * EXTRA_PRICE_PER_CHILD)
}

export const getMonthlyPrice = (appData, formData) => {
  if (isFamily(formData)) {
    console.log('formData.family.selectedPackageId', formData.family.selectedPackageId)
    console.log('appData.familyDataPackages', appData.familyDataPackages)
    const pkg = formData.family.selectedPackageId ? appData.familyDataPackages.find(p => p.id === formData.family.selectedPackageId) : null
    if (pkg) {
      const packagePrice = getFamilySubscriptionTotalPrice(formData.family, pkg)
      return packagePrice
    }
  } else {
    const pkg = formData.phoneItems[0].subscriptionId ? appData.subscriptions.find(p => p.id === formData.phoneItems[0].subscriptionId) : null
    if (pkg) {
      return pkg.amount
    }
  }
}

export const getOrderPriceBreakdown = (appData, formData) => {
  const items = getPriceItems(appData, formData)
  const packageData = getPackage(appData, formData, items)
  const shared = getShared(appData, formData, items, packageData)
  const grossTotal = getGrossTotal(appData, formData, items, packageData, shared)
  const netTotal = subtractDiscountsAndAddExtras(grossTotal, shared.discounts, shared.extras)

  return {
    firstMonth: -1,
    monthlyBreakdown: {
      items,
      package: packageData,
      shared,
      netTotal,
      grossTotal,
    },
  }
}

// EXTRAS:
// { description: 'Digital Trygghet - første måned gratis', amount: -1 },
// { description: 'Tvilling-SIM', amount: -1 },

// DISCOUNTS:
// { description: 'Samlerabatt 15%', amount: -1 },
// { description: 'Under 30 - 15%', amount: -1 },

const getPriceItems = (appData, formData) => {
  const [items, type] = isFamily(formData)
    ? [formData.family.members, 'familyMember']
    : [formData.phoneItems, 'subscription']
  return items.map(i => getPriceItem(appData, formData, i, type))
}

const getPackage = (appData, formData, items) => {
  const extras = getPackageExtras(appData, formData)
  const discounts = getPackageDiscounts(appData, formData)
  const grossAmount = getPackageGrossAmount(appData, formData, items, extras, discounts)
  const netAmount = subtractDiscountsAndAddExtras(grossAmount, discounts, extras)
  return {
    description: getPackagePriceDescription(appData, formData, items),
    grossAmount,
    netAmount,
    extras,
    discounts,
  }
}

const getShared = (appData, formData, items) => {
  return {
    extras: getSharedExtras(appData, formData),
    discounts: getSharedDiscounts(appData, formData, items),
  }
}

const getGrossTotal = (appData, formData, items, packageData, shared) => {
  let sum = packageData.netAmount || 0
  for (let i of items) {
    sum += i.netAmount
  }
  return sum;
}

// UTILITY FUNCTIONS BELOW

const getPackageExtras = (appData, formData) => {
  if (isFamily(formData) && formData.family.digitalSafety) {
    const extra = appData.extraServices.find(e => e.id === config.digitalSafetyId)
    if (extra) {
      return [{
        description: extra.name,
        grossAmount: extra.amount,
        netAmount: extra.amount,
      }]
    } else {
      return []
    }
  } else {
    return []
  }
}

const getPackageDiscounts = (appData, formData) => {
  return []
}

const getSharedExtras = (appData, formData) => {
  return []
}

const getSharedDiscounts = (appData, formData, items) => {
  if (isFamily(formData)) {
    return []
  } else {
    const subItems = items.filter(i => i.type === 'subscription').length
    const sharedCampaignIds = subItems > 1 && formData.bulkInvoiceDiscountActive
      ? [config.bulkInvoiceDiscountId]
      : []
    return getCampaignsWithPriceRules(sharedCampaignIds, appData)
  }
}

const getPackagePriceDescription = (appData, formData, items) => {
  if (isFamily(formData)) {
    const pkg = formData.family.selectedPackageId ? appData.familyDataPackages.find(p => p.id === formData.family.selectedPackageId) : null
    return pkg
      ? pkg.name
      : '(ukjent)'
  } else {
    return '(samlefaktura eller enkeltabo)'
  }
}

const getPackageGrossAmount = (appData, formData, items, extras, discounts) => {
  if (isFamily(formData)) {
    const pkg = formData.family.selectedPackageId ? appData.familyDataPackages.find(p => p.id === formData.family.selectedPackageId) : null
    return pkg
      ? pkg.amount
      : 1000000
  } else {
    return 0
  }
}

const getPriceItem = (appData, formData, item, type) => {
  const extras = getItemExtras(appData, formData, item)
  const discounts = getItemDiscounts(appData, formData, item)
  const grossAmount = getItemGrossAmount(appData, formData, item, type, extras, discounts)
  const netAmount = subtractDiscountsAndAddExtras(grossAmount, discounts, extras)
  console.log('item', item)
  const descriptionExtra = type === 'familyMember' ? ` (${item.type === 'child' ? 'Barn' : 'Familiemedlem'})` : ''
  return {
    type: type,
    member: { type: item.type },
    description: `${item.firstName} ${item.lastName}${descriptionExtra}`,
    grossAmount,
    netAmount,
    extras,
    discounts,
  }
  //   { type: 'family_member', description: 'FAM1', amount: -1, extras: [], discounts: [] },
  //   { type: 'family_member', description: 'FAM2', amount: -1, extras: [], discounts: [] },
  //   // { type: 'subscription', description: 'SUB1', amount: -1, extras: [], discounts: [] },
  //   // { type: 'subscription', description: 'SUB2', amount: -1, extras: [], discounts: [] },
}

const getItemExtras = (appData, formData, item) => {
  console.log('getItemExtras for ', item)
  return (item.extraServices || [])
    .map(e => getItemExtra(appData, item, e))
    .filter(e => e)
}

const getItemDiscounts = (appData, formData, item) => {
  if (isFamily(formData)) {
    // There are no applicable item discounts for families
    return []
  } else {
    return getCampaignsWithPriceRules(item.campaigns || [], appData)
  }
}

const getItemGrossAmount = (appData, formData, item, type, extras, discounts) => {
  return type === 'familyMember'
    ? item.type === 'child' ? EXTRA_PRICE_PER_CHILD : EXTRA_PRICE_PER_ADULT
    : appData.subscriptions.find(p => p.id === item.subscriptionId).amount
}

const getItemDiscount = (campaign, item) => {
  console.log(`priceRule for ${campaign.name}:`, campaign.priceRule)
  return {
    description: campaign.name,
    grossAmount: campaign.amount,
    netAmount: campaign.amount,
  }
}

const getItemExtra = (appData, item, extra) => {
  if (typeof extra === 'string') {
    extra = appData.extraServices.find(e => e.id === extra)
  }
  if (extra) {
    return {
      description: extra.name,
      grossAmount: extra.amount,
      netAmount: extra.amount,
    }
  }
}

const getCampaignsWithPriceRules = (campaigns, appData) => {
  return campaigns
    .map(c => {
      console.log('c', c)
      const campaign = appData.campaigns.find(cmp => cmp.id === c)
      console.log('campaign', campaign)
      return { ...campaign, priceRule: priceRules.discounts[c] }
    })
}

const subtractDiscountsAndAddExtras = (initialSum, discounts, extras) => {
  let sum = initialSum
  for (let discount of discounts) {
    sum = handlePriceRule(sum, discount.priceRule)
  }
  // for (let extra of extras) {
  //   sum += extra.netAmount || 0
  // }
  return sum
}

const handlePriceRule = (sum, priceRule) => {
  if (priceRule) {
    if (priceRule.subtract) {
      sum -= priceRule.subtract
    }
    if (priceRule.percentage) {
      sum = sum * ((100 - priceRule.percentage) / 100)
    }
  }
  return sum
}

window.getOrderPriceBreakdown = getOrderPriceBreakdown

