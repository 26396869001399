import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import _ from 'lodash'

import { setModal, unsetModal } from '../../../../../actions/modal'
import config from '../../../../../config'

class CampaignsComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      subscriptionCampaigns: []
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.subscriptionId !== this.props.subscriptionId)
      this.getSubscriptionsCampaigns()
  }

  componentDidMount() {
    this.getSubscriptionsCampaigns()
  }

  getSubscriptionsCampaigns = () => {
    if (!this.props.subscriptionId)
      return
    axios.get(`${config.apiUrl}/subscription-campaigns/${this.props.subscriptionId}`)
      // axios.get(`${config.apiUrl}/subscriptions/${this.props.subscriptionId}/campaigns?active=true`)
      .then(r => {
        let copy = [...r.data]
        if (this.props.layout.data.departmentId) {
          copy = copy.filter(campaign => (campaign.departmentId && campaign.departmentId === this.props.layout.data.departmentId) || !campaign.departmentId)
        }
        this.setState({ subscriptionCampaigns: copy })
      })
      .catch(e => {
        console.log(e)
      })
  };

  toggleCampaign = (e, currentCampaigns) => {
    currentCampaigns = currentCampaigns || []
    if (e.target.checked) { // add campaign
      currentCampaigns.push(e.target.value)
    } else {
      currentCampaigns = _.remove(currentCampaigns, c => c !== e.target.value)
      // console.log('currentES', currentES)
    }
    return currentCampaigns
  };

  render() {
    let { subscriptionCampaigns } = this.state
    const campaignsAsExtraServices = config.campaignsAsExtraServices.map(c => c[1])
    const realCampaigns = (subscriptionCampaigns || []).filter(c => !campaignsAsExtraServices.includes(c.id))
    subscriptionCampaigns = realCampaigns.filter(sc => sc.active === true)
    console.log('subscriptionCampaigns', subscriptionCampaigns)
    const { item, parent, layout, formData, dispatch } = this.props
    const { departmentId } = (layout?.data || {})
    let campaignMarkup

    if (!item.subscriptionId) {
      campaignMarkup = <p>Velg et abonnement.</p>
    } else {
      campaignMarkup = <p>Ingen tilgjengelige tilbud for abonnementet.</p>
    }

    // Check if there is at least one unlimited subscription
    let hasAtLeastOneUnlimitedSub = false
    formData.phoneItems.forEach(phoneItem => {
      if (config.unlimitedSubscriptionIds.includes(this.props.subscriptionId) && formData.bulkInvoiceDiscountActive) {
        hasAtLeastOneUnlimitedSub = true
      }
    })

    let filteredCampaigns = subscriptionCampaigns.filter(c => {
      return c.id !== config.blackFridayCampaignId && ((c.id !== config.u30CampaignId) || !(config.unlimitedSubscriptionIds.includes(this.props.subscriptionId) && formData.bulkInvoiceDiscountActive))
    })

    // XXX: Hardcoded exception to hide "Utkjøpsrabatt" for Network Negatioations
    // if (departmentId === '66e8b506-fa62-4ab7-a68a-58ec9e555876') {
    //   filteredCampaigns = filteredCampaigns.filter(c => c.id !== config.outletDiscountCampaignId)
    // }

    return (
      <div>
        <h1>Velg tilbud</h1>

        {filteredCampaigns.length ? (
          filteredCampaigns.map(cmp => (
            <React.Fragment key={`cmp-${cmp.id}`}>
              <div className="input_wrapper">
                <input type="checkbox"
                  value={cmp.id}
                  id={`cmp-${cmp.id}`}
                  checked={item.campaigns.indexOf(cmp.id) === -1 ? false : true}
                  onChange={e => {
                    if (item.campaigns && item.campaigns.length < 3 || item.campaigns.indexOf(cmp.id) !== -1) {
                      const newCampaigns = this.toggleCampaign(e, item.campaigns)
                      const newItem = { ...item, campaigns: newCampaigns }
                      parent.setState({ item: newItem })
                    } else {
                      dispatch(setModal({
                        show: true,
                        heading: 'Kun to tilbud kan velges.',
                        body: <p>Det er kun mulig å kombinere to tilbud.</p>,
                        button: <button className="secondary large" onClick={() => dispatch(unsetModal())}>Ok</button>
                      }))
                    }
                  }} />
                <label htmlFor={`cmp-${cmp.id}`}><span>{cmp.name}</span></label>
              </div>
            </React.Fragment>
          )
          )
        ) : (
          campaignMarkup
        )}
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    formData: state.formData,
    layout: state.layout
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignsComponent);
