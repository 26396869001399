import React, {Component} from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import {toastr} from 'react-redux-toastr'

import config from '../../../config'
import { checkIfLoggedIn } from '../../../auth'

class SignInForm extends Component {
  constructor(props){
    super(props)
    this.state = {
      identity: '',
      password: '',
      error: '',
    }
  }

  signIn = (e) => {
    e.preventDefault()
    axios.post(`${config.apiUrl}/authenticate`, this.state)
      .then(r => {
        if (r.data.data.token) {
          const myStorage = window.localStorage
          myStorage.setItem('token', `Bearer ${r.data.data.token}`)
          checkIfLoggedIn({dispatch: this.props.dispatch})
        } else {
          this.setState({error: 'Sorry, error signing you in. Please try again.'})
        }
      })
      .catch(e => {
        console.error('error: ', e)
        toastr.error('Kunne ikke logge inn', `Prøv igjen eller kontakt teamleader`)
      })
  };

  render() {
    return (
      <form onSubmit={e => this.signIn(e)}>
        <div className="input_wrapper">
          <input type="text" placeholder="Brukernavn/Epost/Tlf.nr." required value={this.state.identity} onChange={e => {
            this.setState({identity: e.target.value})
          }} />
        </div>
        <div className="input_wrapper">
          <input type="password" placeholder="Passord" required value={this.state.password} onChange={e => {
            this.setState({password: e.target.value})
          }} />
        </div>
        <span>{this.state.error}</span>
        <button className="login">Logg inn</button>
      </form>
    )
  }
}

const mapStateToProps = state => {
  return {
    anon: state.anon,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignInForm)