
import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'

import moment from 'moment'
import DatePicker from 'react-datepicker'
import config from '../../../../../config'
import { validateFamilyMember } from '../../../../../lib/family_validation'
import PortType from '../../../../PortType'

import 'react-datepicker/dist/react-datepicker.css'

import { setFormData } from '../../../../../actions/formData'

class MemberComponent extends Component {
  constructor(props) {
    super(props)

    const { member } = props

    this.memberRef = React.createRef()

    this.state = {
      editing: false,
      member: {
        ...member,
        useOwnerAddress: (typeof member.useOwnerAddress !== 'undefined' ? member.useOwnerAddress : true),
        extraAbroad: (typeof member.extraAbroad !== 'undefined' ? member.extraAbroad : false),
        extraAbroadId: (typeof member.extraAbroadId !== 'undefined' ? member.extraAbroadId : false),
      },
    };
  }

  handleChange = (e) => {
    if (e.target) {
      this.setState({
        ...this.state,
        member: {
          ...this.state.member,
          [e.target.name]: e.target.value,
        },
      });
    } else {
      this.setState({
        ...this.state,
        member: {
          ...this.state.member,
          dateOfBirth: moment(e).format('YYYY-MM-DD'),
        },
      });
    }
  }

  valid = () => {
    const { member } = this.state

    return validateFamilyMember(member)
  }

  save = () => {
    const { i, formData, dispatch } = this.props;

    if (!validateFamilyMember(this.state.member)) {
      return false;
    }

    const members = [...formData.family.members];
    members[i] = {
      ...this.state.member,
      set: true
    }

    dispatch(setFormData({
      family: {
        ...formData.family,
        members: members,
      }
    }));

    this.setState({
      ...this.state,
      member: {
        ...members[i],
        set: true,
      },
    }, () => {
      this.toggleEditing()
    });
  }

  deleteMember = (idx) => {
    const { formData, dispatch } = this.props

    const members = [...formData.family.members]
    members.splice(idx, 1)

    const counts = {
      ...formData.family.counts,
      [this.state.member.type]: formData.family.counts[this.state.member.type] - 1,
    }

    dispatch(setFormData({
      family: {
        ...formData.family,
        members: members,
        counts: counts,
      }
    }));
  }

  toggleEditing = () => {
    const { toggleEditing } = this.props

    const newEditingState = !this.state.editing

    this.setState({
      ...this.state,
      editing: newEditingState,
    }, () => {
      if (newEditingState === true) {
        this.memberRef.scrollIntoView({
          behavior: 'smooth'
        })
      }

      toggleEditing(newEditingState)
    })
  }

  render() {
    const { i, formData, appData, dispatch, layout } = this.props
    const { member } = this.state;

    if (!formData.family.members[i]) return null;

    const { ownerWantsSubscription } = formData.family;

    const isOwner = member.owner;

    const typeName = member.type === 'child' ? 'Barn' : 'Familiemedlem'
    const name = formData.family.members[i].firstName ? `${formData.family.members[i].firstName} ${formData.family.members[i].lastName}` : typeName;
    const familyExtraServices = appData.familyExtraServices || []
    const filteredFamilyExtraServices = config.campaignsAsExtraServices.map(c => { return { id: c[1], name: c[0], active: true } })
      .concat(familyExtraServices)
      .filter(s => s.active && s.id !== config.digitalSafetyId)

    return (
      <div ref={(ref) => this.memberRef = ref} key={`member-${i}`} className={isOwner ? 'memberContainer owner' : (member.set ? 'memberContainer' : (this.state.editing ? 'memberContainer unset editing' : 'memberContainer unset'))}>
        <div className="main">
          <div className="person">
            <div className="name">
              {name}
            </div>
            <div className="age">
              {member.dateOfBirth ? `${typeName}, ${moment().toDate().getFullYear() - moment(member.dateOfBirth).toDate().getFullYear() + ' år'}` : (isOwner ? 'Sjefen' : 'Knytt til person')}
              {member.owner && ownerWantsSubscription &&
                <div className="ownerWantsSubscriptionText">Skal ha eget abonnement</div>
              }
              {member.owner && !ownerWantsSubscription &&
                <div className="ownerWantsSubscriptionText">Skal ikke ha eget abonnement</div>
              }
            </div>
          </div>
          {isOwner &&
            <button className="icon edit-item" onClick={() => dispatch(setFormData({ familyStep: 'owner' }))}></button>
          }
          {!isOwner &&
            <div style={{ textAlign: 'right' }}>
              <button className={`icon delete-item${(this.state.editing ? ' editing' : '')}`} onClick={() => this.deleteMember(i)}></button>
              <button className={`icon edit-item${(this.state.editing ? ' editing' : '')}`} onClick={this.toggleEditing}></button>
            </div>
          }
        </div>
        {this.state.editing &&
          <div className="editingContainer">
            <div className="input_wrapper"><label htmlFor="firstName">Fornavn og mellomnavn:</label>
              <input type="text" id="firstName" name="firstName" value={this.state.member.firstName || ''} onChange={this.handleChange} />
            </div>

            <div className="input_wrapper"><label htmlFor="lastName">Etternavn:</label>
              <input type="text" id="lastName" name="lastName" value={this.state.member.lastName || ''} onChange={this.handleChange} />
            </div>

            <div className="input_wrapper"><label htmlFor="lastName">Telefonnr:</label>
              <input type="text" id="phone" name="phone" value={this.state.member.phone || ''} onChange={this.handleChange} />
            </div>

            <div className="input_wrapper"><label htmlFor="dateofbirth">Fødselsdato:</label>
              <DatePicker
                name="dateOfBirth"
                dateFormat="dd/MM/yyyy"
                className="form-control"
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                maxDate={moment().toDate()}
                selected={this.state.member.dateOfBirth ? moment(this.state.member.dateOfBirth).toDate() : null}
                onChange={this.handleChange} />
            </div>

            <div className="input_wrapper"><label htmlFor="email">E-post:</label>
              <input type="text" id="email" name="email" value={this.state.member.email || ''} onChange={this.handleChange} />
            </div>

            {member.type === 'child' &&
              <div className="input_wrapper more-spacing less-bottom-spacing">
                <input type="checkbox"
                  value={this.state.member.childMode}
                  id="child_mode"
                  checked={this.state.member.childMode}
                  onChange={e => {
                    this.setState({
                      ...this.state,
                      member: {
                        ...this.state.member,
                        childMode: e.target.checked,
                      }
                    });
                  }} />
                <label htmlFor="child_mode"><span>Barnemodus</span></label>
              </div>
            }

            <React.Fragment>
              <h3>Tilleggstjenester for familiemedlem</h3>
              {filteredFamilyExtraServices.map(svc => {
                const extraServices = this.state.member.extraServices || []
                const existingIdx = extraServices.findIndex(s => s === svc.id)
                return (
                  <div className="input_wrapper" key={svc.id}>
                    <input type="checkbox"
                      value={1}
                      id={`member_${svc.id}`}
                      checked={existingIdx !== -1}
                      onChange={e => {
                        const newExtraServices = e.target.checked
                          ? existingIdx === -1 ? extraServices.concat([svc.id]) : extraServices
                          : existingIdx === -1 ? extraServices : extraServices.slice(0, existingIdx).concat(extraServices.slice(existingIdx + 1))
                        this.setState({
                          member: {
                            ...this.state.member,
                            extraServices: newExtraServices
                          }
                        })
                      }} />
                    <label htmlFor={`member_${svc.id}`}><span>{svc.name}</span></label>
                  </div>
                )
              })}
            </React.Fragment>

            <div className="input_wrapper more-spacing">
              <div className="input_wrapper">
                <input type="radio" value={true} id="use-owner-address" checked={member.useOwnerAddress === true} onClick={e => {
                  this.setState({ ...this.state, member: { ...member, useOwnerAddress: true, address: '', postalCode: '', postalArea: '' } })
                }} onChange={e => { }} />
                <label htmlFor="use-owner-address"><span>Bruk samme adresse som eier</span></label>
              </div>

              <div className="input_wrapper">
                <input type="radio" value={false} id="use-own-address" checked={member.useOwnerAddress === false} onClick={e => {
                  this.setState({ member: { ...member, useOwnerAddress: false, address: '', postalCode: '', postalArea: '' } })
                }} onChange={e => { }} />
                <label htmlFor="use-own-address"><span>Har egen adresse</span></label>
              </div>
            </div>

            {member.useOwnerAddress === false &&
              <div>
                <div className="input_wrapper"><label htmlFor="address">Gateadresse:</label>
                  <input type="text" id="address" name="address" value={this.state.member.address} onChange={this.handleChange} />
                </div>

                <div className="input_wrapper"><label htmlFor="postalCode">Postnummer:</label>
                  <input type="text" maxLength="4" id="postalCode" name="postalCode" value={this.state.member.postalCode} onChange={this.handleChange} />
                </div>

                <div className="input_wrapper"><label htmlFor="postalArea">Poststed:</label>
                  <input type="text" id="postalArea" name="postalArea" value={this.state.member.postalArea} onChange={this.handleChange} />
                </div>
              </div>
            }

            <PortType component={this} person={this.state.member} parentObject={'member'} />

            <div className="buttons">
              <button className="cancel" onClick={this.toggleEditing}>Avbryt</button>
              <button className="save right" disabled={!this.valid()} onClick={this.save}>Lagre</button>
            </div>
          </div>
        }
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    appData: state.appData,
    formData: state.formData,
    layout: state.layout,
  }
}

const Member = connect(
  mapStateToProps,
  mapDispatchToProps
)(MemberComponent)

export default Member
