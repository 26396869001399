import React, { Component } from 'react'

export default class Loading extends Component {
  render() {
    return (
      <div className="loading-container" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '800px'}}>
        <div style={{fontSize: '48px', fontWeight: '800', color: '#ddd'}}>Laster...</div>
      </div>
    )
  }
}
