import {combineReducers} from 'redux'

import AuthenticateReducer from './authenticate' // are you signed in/out
import UserReducers from './user'; // current signed in user information
import LayoutReducers from './layout'; // current layout
import FormDataReducers from './formData'
import AppDataReducers from './appData'
import ModalReducers from './modal'

import {reducer as toastrReducer} from 'react-redux-toastr'

export default combineReducers({
  authenticate: AuthenticateReducer,
  user: UserReducers,
  layout: LayoutReducers,
  formData: FormDataReducers,
  appData: AppDataReducers,
  modal: ModalReducers,
  toastr: toastrReducer
});
