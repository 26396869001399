import React, { Component } from 'react'
import { connect } from 'react-redux'

import { validateFamilyOwner } from '../../../../../lib/family_validation'
import { setFormData } from '../../../../../actions/formData'
import lookupPerson from '../../../../../lib/person_lookup'
import Modal from '../../../../Modal'
import OwnerInput from '../../../../OwnerInput'

class FamilyStep2Component extends Component {
  constructor(props) {
    super(props)
    const { formData } = props
    this.lookupPerson = lookupPerson.bind(this)

    this.state = {
      errors: {
        phone: false,
      },
      valid: {
        phone: false,
      },
      ownerWantsSubscription: formData.family.hasOwnProperty('ownerWantsSubscription') ? formData.family.ownerWantsSubscription : true,
      owner: this.getOwner() || {
        id: Math.random().toString(),
        verified: false,
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        ssn: '',
        extraAbroad: false,
        extraAbroadId: null,
        twinSim: false,
        dataCard: false,
      },
    };
  }

  handleChange = (e, resetVerification) => {
    this.setState({
      ...this.state,
      owner: {
        ...this.state.owner,
        verified: (resetVerification ? false : this.state.owner.verified),
        [e.target.name]: e.target.value,
      },
    });
  }

  capitalizeFirstLetter = (string) => {
    string = string.toLowerCase()
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  getOwner = () => {
    const { formData } = this.props
    return formData.family.members.find(m => m.owner)
  }

  allowNext = () => {
    const { owner } = this.state
    const { formData } = this.props
    const family = {
      ...formData.family,
      ownerWantsSubscription: this.state.ownerWantsSubscription,
    }

    return validateFamilyOwner(owner, family)
  };

  prevStep = () => {
    const { dispatch } = this.props
    dispatch(setFormData({ familyStep: 'package' }))
  };

  hasAdults = () => {
    const { formData } = this.props
    return formData.family.members.filter((m) => m.type === 'adult').length > 0;
  }

  nextStep = () => {
    const { formData, dispatch } = this.props
    const members = [...formData.family.members];
    const ownerIdx = members.findIndex(m => m.owner);
    const firstAdultIdx = members.findIndex(m => m.type === 'adult');
    const adultMembers = members.filter(m => m.type === 'adult').length;
    const adultCount = formData.family.counts.adult;
    const ownerWantsSubscription = this.state.ownerWantsSubscription;

    if (ownerIdx !== -1) {
      console.log('Has owner..');
      // We have the owner as a member. Does he have subscription? If so, the number of adult members should equal counts
      if (ownerWantsSubscription && adultMembers === adultCount) {
        console.log('Update owner with sub, all good..');
        // All good. Just update subscription.
        members[ownerIdx] = {
          ...this.state.owner,
          id: this.state.owner.id ? this.state.owner.id : Math.random(),
          type: 'adult',
          owner: true,
          set: true,
          subscription: ownerWantsSubscription,
        };
      } else if (ownerWantsSubscription && adultMembers > adultCount) {
        console.log('Remove first adult after owner..');
        // Remove first adult after owner
        if (members[ownerIdx + 1]) {
          members.splice(ownerIdx + 1, 1);
        }
      } else if (!ownerWantsSubscription) {
        // Owner does not have a subscription. Adult members should be one more than count.
        if (adultMembers === adultCount) {
          console.log('Add one adult member..');
          // There are too few members. Add one adult member.
          members.splice(ownerIdx + 1, 0, {
            type: 'adult',
            set: false,
            subscription: true,
            id: Math.random(),
          });
        } else if (adultMembers < adultCount) {
          console.log('Update owner without sub..');
          members[ownerIdx] = {
            ...this.state.owner,
            id: this.state.owner.id ? this.state.owner.id : Math.random(),
            type: 'adult',
            owner: true,
            set: true,
            subscription: ownerWantsSubscription,
          };
        }
      }
    } else {
      if (!ownerWantsSubscription) {
        // Add owner..
        members.unshift({
          ...this.state.owner,
          type: 'adult',
          owner: true,
          set: true,
          subscription: ownerWantsSubscription,
          id: Math.random(),
        });
      } else {
        // Update first adult with owner
        members[firstAdultIdx] = {
          ...this.state.owner,
          type: 'adult',
          owner: true,
          set: true,
          subscription: ownerWantsSubscription,
        };
      }
    }

    members.sort((a, b) => {
      if (a.owner && !b.owner) {
        return -1;
      }

      if (a.type === 'adult' && b.type !== 'adult') {
        return -1;
      }
      if (a.type === 'child' && b.type !== 'child') {
        return 1;
      }

      return 0;
    });

    dispatch(setFormData({
      familyStep: 'members',
      family: {
        ...formData.family,
        members: members,
        ownerWantsSubscription: this.state.ownerWantsSubscription,
      },
    }))
  };

  render() {
    const { formData, dispatch } = this.props
    const { lookupMethod } = formData

    return (
      <div className="owner-registration-container">
        <h1>Hvem er sjefen?</h1>
        <p>Vi trenger å vite litt mer om den som skal stå som juridisk eier av abonnementene.</p>
        <OwnerInput component={this} person={this.state.owner} mode="family" handleChange={this.handleChange} />
        <div className="buttons">
          <button className="cancel" onClick={this.prevStep}>Tilbake</button>
          <button className="continue" disabled={!this.allowNext()} onClick={this.nextStep}>Fortsett</button>
        </div>
        <Modal />
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    formData: state.formData,
    layout: state.layout,
  }
}

const FamilyStep2 = connect(
  mapStateToProps,
  mapDispatchToProps
)(FamilyStep2Component)

export default FamilyStep2
