window.process = {
  ...window.process,
};

let storedState
if (process && process.env && process.env.REACT_APP_USE_STORED_STATE) {
  try {
    const fullState = window.localStorage.getItem('_state')
    if (fullState) {
      storedState = JSON.parse(fullState).user
    }
  } catch (e) {
    console.error('Unable to set initial state from local storage:', e)
  }
}

const initialState = storedState || {}

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_USER':
      return { ...state, ...action.user }
    case 'UNSET_USER':
      return null
    default:
      return state;
  }
}