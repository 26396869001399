
import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import axios from 'axios'
import moment from 'moment'
import validate from 'validate.js'
import DatePicker from 'react-datepicker'

import config from '../../../../config'

import { setFormData } from '../../../../actions/formData'
import { randStr } from '../../../../lib/utils'

import Subscriptions from './Subscriptions'
import Campaigns from './Campaigns'
import ExtraServices from './ExtraServices'
import ExtraAbroadCountry from './ExtraServices/ExtraAbroadCountry'
import OwnerInput from '../../../OwnerInput'
import DateOfBirthPicker from '../../../DateOfBirthPicker'
import SpecialCampaign from '../../../SpecialCampaign'

const operators = [{
  id: 1,
  name: 'Telenor',
  allowed: false,
}, {
  id: 2,
  name: 'Talkmore',
  allowed: false,
}, {
  id: 3,
  name: 'Telia',
  allowed: true,
}, {
  id: 4,
  name: 'ICE.net',
  allowed: true,
}, {
  id: 5,
  name: 'OneCall',
  allowed: true,
}, {
  id: 6,
  name: 'Komplett Mobil',
  allowed: true,
}, {
  id: 7,
  name: 'Fjordkraft',
  allowed: true,
}, {
  id: 8,
  name: 'Annen operatør',
  allowed: true,
}]

const constraints = {
  simNumber: {
    length: {
      is: 12
    }
  },
  ssn: {
    length: {
      is: 11
    }
  },
  birthDate: {
    format: {
      pattern: /^([012][0-9]\.[01][0-9]\.[12][0-9][0-9][0-9])$/,
    },
    length: {
      is: 10
    }
  },
};

const alwaysAddOnServiceIds = ['4d03eef1-5404-4a94-a65a-48a49a149ac4', 'dbddf5ce-e6ab-4096-90e8-673c128cb5fa']

const specialCampaignSubIds = [
  'ab7b4d66-8ebc-4212-b3f7-5dcb5a492b11', // Enkeltabonnement 1 GB
  'c396b464-150c-4431-a7cd-4f2b422fd10b', // Enkeltabonnement 2 GB
  '66559ff0-d88e-415a-b053-8060e9329e4e', // Enkeltabonnement 6 GB
  '2eccbf94-7fc3-464b-ad68-48566f990c27', // Enkeltabonnement 8 GB
  'c0b9ce36-552c-427d-8f36-51e7d2c81acb', // Enkeltabonnement 15 GB
  '3e3e14b0-ec9c-433c-9605-b788fc17ba8d', // Enkeltabonnement 25 GB
]

const defaultItem = {
  campaigns: [],
  extraServices: [],
  simMethod: 'auto',
  portingTime: 5,
}

class AddPhoneItemComponent extends Component {
  constructor(props) {
    super(props)
    const { formData, layout } = props
    const { departmentId } = layout.data

    const existingItem = typeof props.editIndex === 'number'
      ? formData.phoneItems[props.editIndex]
      : null
    const dob = existingItem && existingItem.dateOfBirth ? moment(existingItem.dateOfBirth).format('DD.MM.YYYY') : ''
    const tweakedDefaultItem = { ...defaultItem }
    if (departmentId === config.ukraineDepartmentId) {
      tweakedDefaultItem.purchaseMethod = 'new'
      tweakedDefaultItem.subscriptionId = config.ukraineSubscriptionId
      tweakedDefaultItem.verified = true
      tweakedDefaultItem.isOwner = true
      tweakedDefaultItem.campaigns = config.ukraineCampaigns
      tweakedDefaultItem.simMethod = 'input'
    }
    this.state = {
      ownerWantsSubscription: true,
      showAddOnModal: false,
      item: existingItem || tweakedDefaultItem,
      dob: dob,
      extraServices: [],
      choices: {},
      errors: {
        simNumber: false,
      },
      valid: {
        simNumber: false,
      },
      simError: false,
    }
    if (this.state.item && this.state.item.subscriptionId) {
      this.getSubscriptionsExtraServices(this.state.item.subscriptionId)
    }
  }

  getSubscriptionsExtraServices = subscriptionId => {
    // axios.get(`${config.apiUrl}/subscriptions/${subscriptionId}/extra-services?active=true&perPage=50&order=order`)
    axios.get(`${config.apiUrl}/subscription-extra-services/${subscriptionId}`)
      .then(r => {
        // let extraServices = [...r.data]
        // // filter away extra services that should not be allowed for current agency
        // if ( _.has(this.props, 'layout.data.departmentId') ) {
        //   const depId = this.props.layout.data.departmentId
        //   // Only remove if it's not one of these departments
        //   const depsNotChecked = [
        //     '8fd4decf-133e-49fb-b456-f9c0ba65c238', // MTMST
        //     '6e8e995c-5070-4071-a487-09948a761d65', // Adicio
        //     'b05466bf-bdec-4303-a121-de848c6c85a0', // Adiciocanvas
        //     '89139c3e-ae02-4473-9a3d-ceff542c92b7', // AB marketing - added 2019-10-29 by stian
        //     'a859e4a0-14da-44cb-997c-4fa31fdba19c', // Salestek - added 2019-12-10 by stian
        //   ]
        //   if (depsNotChecked.indexOf(depId) === -1) {
        //     // remove selected extra service
        //     extraServices = _.reject(extraServices, {id: '4d03eef1-5404-4a94-a65a-48a49a149ac4'}) // Digital Trygghet
        //   }
        // }
        // this.setState({extraServices: extraServices})
        this.setState({ extraServices: r.data })
      })
      .catch(e => {
        console.log(e)
      })
  };

  isOwner = () => {
    const { formData, editIndex } = this.props
    return editIndex === 0 || !formData.phoneItems.length
  }

  handleChange = (e, resetVerification = false, forceVerified = false) => {
    const { layout } = this.props
    const { departmentId } = layout.data
    const isUkraineFlow = departmentId === config.ukraineDepartmentId

    const newItem = {
      ...this.state.item,
      verified: isUkraineFlow ? true : (resetVerification ? false : (forceVerified || this.state.item.verified)),
      [e.target.name]: e.target.value,
    }
    // if (e.target.name === 'phone' && (!this.state.item.portPhone || this.state.item.portPhone === this.state.item.phone)) {
    //   newItem.portPhone = e.target.value
    // }
    this.setState({
      item: newItem
    });
  }

  toggleExtraService = (id, checked, currentES, fromAlwaysAddOn) => {
    currentES = currentES || []
    if (!fromAlwaysAddOn && alwaysAddOnServiceIds.includes(id)) {
      const choicesCopy = { ...this.state.choices }
      if (checked) {
        choicesCopy[id] = 1
      } else {
        delete choicesCopy[id]
      }
      this.setState({ choices: choicesCopy })
    }
    if (checked) { // add extra service
      currentES.push(id)
      return currentES
    } else {
      currentES = _.remove(currentES, es => es !== id)
      // console.log('currentES', currentES)
      return currentES
    }
  };

  allowSave = (formData) => {
    const { item } = this.state
    const { layout } = this.props
    const { departmentId } = layout.data
    if (this.doPersonLookupCheck() && this.doPreCheck() && this.doOperatorCheck()) {
      if (item.subscriptionId) {
        if (departmentId === config.ukraineDepartmentId) {
          return item.firstName && item.lastName && item.dateOfBirth && item.address && item.postalCode && item.postalArea && item.simNumber
        } else {
          const chosenProduct = _.find(this.props.subscriptions, sub => {
            return sub.id === item.subscriptionId
          })
          if ((item.extraServices.indexOf('Ekstra Utland') !== -1 && !item.extraAbroadId) ||
            (chosenProduct.kidSafe === true && (!item.childsName || !item.childsDateOfBirth))) {
            return false
          } else {
            if (item.purchaseMethod === 'port' && !item.phone) {
              return false
            } else {
              return true
            }
          }
        }
      }
    }
    return false
  };

  showAddOnModal = () => {
    const { item, extraServices } = this.state
    const { layout } = this.props
    const { departmentId } = layout.data

    if (departmentId === config.ukraineDepartmentId) {
      this.addItem()
      return
    }
    // Use the filtered list instead of the list of IDs, in case some of them are missing
    const alwaysAddonServices = extraServices.filter(e => alwaysAddOnServiceIds.includes(e.id))
    // If they are all set in the choices object at this point, it means they are all YES, 
    // so we can skip the Always Add-On modal
    const choices = {}
    alwaysAddonServices.forEach(s => {
      if (item.extraServices.includes(s.id)) {
        choices[s.id] = 1
      }
    })

    this.setState({ choices }, () => {
      if (Object.keys(this.state.choices).length === alwaysAddonServices.length) {
        this.addItem()
      } else {
        this.setState({ showAddOnModal: true })
      }
    })

  };

  toggleChoice = (id, value) => {
    const { item } = this.state
    const choices = { ...this.state.choices, [id]: value }
    this.setState({ choices })
    const newExtraServices = this.toggleExtraService(id, value, item.extraServices, true)
    const newItem = { ...item, extraServices: newExtraServices }
    this.setState({ item: newItem })
  };

  changeSubscription = subscriptionId => {
    // const { dispatch } = this.props
    // dispatch(setFormData({product: subscriptionId, campaigns: [], extraServices: []}))
    this.getSubscriptionsExtraServices(subscriptionId)
    const item = { ...this.state.item, subscriptionId, campaigns: [], extraServices: [] }
    this.setState({ item, choices: {} })
  };

  addItem = () => {
    const { formData, dispatch, parent, editIndex } = this.props
    const newItem = { ...this.state.item, _id: randStr(16) }
    let phoneItems
    if (typeof editIndex === 'number') {
      phoneItems = formData.phoneItems.slice(0, editIndex).concat([newItem]).concat(formData.phoneItems.slice(editIndex + 1))
    } else {
      phoneItems = formData.phoneItems.concat([newItem])
    }
    dispatch(setFormData({ phoneItems, newPhoneSession: false }))
    parent.setState({ showAddPhoneModal: false })
  };

  close = () => {
    const { dispatch, parent } = this.props
    dispatch(setFormData({ newPhoneSession: false }))
    parent.setState({ showAddPhoneModal: false })
  };

  doPersonLookupCheck = () => {
    const { formData } = this.props
    const { phoneItems } = formData
    return Boolean(this.state.item.verified || phoneItems.length)
  };

  doOperatorCheck = () => {
    const { item } = this.state
    if (item.purchaseMethod === 'new') {
      return true
    } else {
      const { operatorId } = item
      if (operatorId) {
        const operator = operators.find(o => o.id === operatorId)
        if (operator && operator.allowed) {
          return true
        }
      }
      return false
    }
  };

  doPreCheck = () => {
    const { item } = this.state
    if (item.purchaseMethod === 'new') {
      return true
    } else if (item.purchaseMethod === 'port') {
      return this.doOperatorCheck()
    }
    return false
  };

  _render_debugInfo = () => {
    const { item } = this.state

    return (
      <div className="debug_element">
        <h2>Item</h2>
        <pre>{JSON.stringify(item, null, 2)}</pre>
      </div>
    )
  }

  _render_addOnModal = () => {
    const { item, choices, extraServices } = this.state
    const alwaysAddonServices = extraServices.filter(e => alwaysAddOnServiceIds.includes(e.id))

    return (
      <div>
        <div>
          <h3>Sjekkliste Tilleggstjenester</h3>
          <p>Husk å informere kunden om Talkmore sine gode tilleggstjenester.</p>
        </div>
        <div className="toggles">
          {alwaysAddonServices.map(service => (
            <div key={service.id} className={`${Object.keys(choices).includes(service.id) ? 'ok' : ''}`}>
              <div>
                <div>{service.name}</div>
                <div className="toggle-buttons">
                  <div className={`oh-yeah ${choices[service.id] === 1 ? 'active' : ''}`} onClick={e => this.toggleChoice(service.id, 1)}>Ja takk!</div>
                  <div className={`oh-noes ${choices[service.id] === 0 ? 'active' : ''}`} onClick={e => this.toggleChoice(service.id, 0)}>Nei takk</div>
                </div>
              </div>
              <ExtraAbroadCountry item={item} extraService={service} />
            </div>
          ))}
        </div>
        <div className="buttons">
          <button className="cancel" onClick={() => this.setState({ choices: {}, showAddOnModal: false })}>Tilbake</button>
          <button className="continue" disabled={Object.keys(choices).length !== alwaysAddonServices.length} onClick={this.addItem}>Fortsett</button>
        </div>
      </div>
    )
  }

  _render_newOrPort = () => {
    const { item } = this.state

    return (
      <React.Fragment>
        <h1>Nytt nummer eller portere?</h1>
        <div className="input_wrapper">
          <input type="radio" value={true} id="new-number" checked={item.purchaseMethod === 'new'} onClick={e => {
            this.setState({ item: { ...item, purchaseMethod: 'new', phone: '', portingDate: null, simNumber: '' } })
          }} onChange={e => { }} />
          <label htmlFor="new-number"><span>Ønsker nytt telefonnr</span></label>
        </div>

        <div className="input_wrapper">
          <input type="radio" value={false} id="port-number" checked={item.purchaseMethod === 'port'} onClick={e => {
            this.setState({ item: { ...item, purchaseMethod: 'port' } })
          }} onChange={e => { }} />
          <label htmlFor="port-number"><span>Portere eksisterende telefonnr</span></label>
        </div>
      </React.Fragment>
    )
  }

  _render_selectOperator = () => {
    const { item } = this.state
    let operator
    const { operatorId } = item
    if (operatorId) {
      operator = operators.find(o => o.id === operatorId)
    }

    return (
      <div>
        <h1>Hvilken operatør har du i dag?</h1>
        {operators.map(opr => (
          <div className="input_wrapper" key={opr.id}>
            <input type="radio" name="operator" value={opr.id} id={`operator-${opr.name}`} checked={item.operatorId === opr.id} onChange={e => {
              this.setState({ item: { ...item, operatorId: parseInt(opr.id, 10) } })
            }} />
            <label htmlFor={`operator-${opr.name}`}><span>{opr.name}</span></label>
          </div>
        ))}
        {operator && !operator.allowed &&
          <div>
            <h2>Vi overfører normalt ikke eksisterende Talkmore eller Telenor-kunder</h2>
            <p>Eventuelle unntak må godkjennes av teamleder.</p>
          </div>
        }
      </div>
    )
  }

  _render_subscription = () => {
    const { item } = this.state
    const { subscriptions } = this.props

    return (
      <Subscriptions item={item} subscriptions={subscriptions} changeSubscription={this.changeSubscription} />
    )
  }

  _render_campaigns = () => {
    const { item } = this.state

    return (
      <Campaigns parent={this} item={item} subscriptionId={item.subscriptionId} />
    )
  }

  _render_portPhone = () => {
    const { item } = this.state
    return (
      <div className="input_wrapper"><label htmlFor="portPhone">Telefonnr.:</label>
        <input type="phone" id="portPhone" value={item.portPhone || ''} onChange={e => { this.setState({ item: { ...item, portPhone: e.target.value } }) }} />
      </div>
    )
  }

  _render_portDate = () => {
    const { item } = this.state
    const baseDate = moment().hour(12).date(14)
    const validDateIntervals = (item.campaigns || []).includes(config.portDiscountId)
      ? [
        { start: baseDate.toDate(), end: moment(baseDate).date(20).toDate() },
        { start: moment(baseDate).add(1, 'month').toDate(), end: moment(baseDate).add(1, 'month').date(20).toDate() },
        { start: moment(baseDate).add(2, 'month').toDate(), end: moment(baseDate).add(2, 'month').date(20).toDate() },
        { start: moment(baseDate).add(3, 'month').toDate(), end: moment(baseDate).add(3, 'month').date(20).toDate() },
      ] : null

    return (
      <div>
        <div className="header_wrapper">
          <h1>Porteringstidspunkt</h1>
          <p>Dersom du ikke velger porteringstidspunkt, er det standard porteringstid som er gjeldende (6 virkedager frem, kl. 0500).</p>
        </div>
        <div className="port-time-wrapper">
          <div>
            <div class="column-heading">Dato</div>
            <div className="input_wrapper">
              <DatePicker
                dateFormat="dd/MM/yyyy"
                className="form-control"
                selected={item.portingDate ? moment(item.portingDate).toDate() : null}
                minDate={this.state.valid.simNumber ? moment().toDate() : moment().add(7, 'days').toDate()}
                includeDateIntervals={validDateIntervals}
                onChange={date => { console.log(date); this.setState({ item: { ...item, portingDate: date ? moment.utc(moment(date).startOf('day') + ((86400 * 1000) / 2)) : null } }); }} />
            </div>
          </div>
          <div>
            <div class="column-heading">Tidspunkt</div>
            <div className="input_wrapper">
              <select
                className="port-time-selector"
                disabled={!Boolean(item.portingDate)}
                value={item.portingDate ? (item.portingTime || 5).toString() : 5}
                onChange={ev => { const portTime = ev.target.value; console.log(portTime); this.setState({ item: { ...item, portingTime: parseInt(portTime) } }); }}>
                <option value="5">05:00</option>
                <option value="6">06:00</option>
                <option value="7">07:00</option>
                <option value="8">08:00</option>
                <option value="9">09:00</option>
                <option value="10">10:00</option>
                <option value="11">11:00</option>
                <option value="12">12:00</option>
                <option value="13">13:00</option>
                <option value="14">14:00</option>
                <option value="15">15:00</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    )
  }

  _render_familyMemberDetails = () => {
    const { item } = this.state

    return (
      <div style={{ marginBottom: '1rem' }}>
        <h1>Personalia for familiemedlem</h1>
        <div className="input_wrapper"><label htmlFor="firstName">Fornavn:</label>
          <input type="text" id="firstName" value={item.firstName || ''} onChange={e => { this.setState({ item: { ...item, firstName: e.target.value } }) }} />
        </div>
        <div className="input_wrapper"><label htmlFor="lastName">Etternavn:</label>
          <input type="text" id="lastName" value={item.lastName || ''} onChange={e => { this.setState({ item: { ...item, lastName: e.target.value } }) }} />
        </div>
        <div className="input_wrapper"><label htmlFor="phone">Telefonnr.:</label>
          <input type="text" id="phone" value={item.phone || ''} onChange={e => { this.setState({ item: { ...item, phone: e.target.value } }) }} />
        </div>
        <div className="input_wrapper"><label htmlFor="email">Epost:</label>
          <input type="text" id="email" value={item.email || ''} onChange={e => { this.setState({ item: { ...item, email: e.target.value } }) }} />
        </div>

        <DateOfBirthPicker
          error={this.state.errors && this.state.errors.dateOfBirth}
          valid={this.state.valid && this.state.valid.dateOfBirth}
          selected={item.dateOfBirth}
          onChange={value => this.setState({ item: { ...item, dateOfBirth: value } })}
        />

        <div style={{ marginTop: '2rem' }}>
          <h1>Kjønn</h1>
          <div className="input_wrapper">
            <input type="radio" value={true} id="gender-o" checked={['M', 'F'].indexOf(item.gender) === -1} onClick={e => this.setState({ item: { ...item, gender: '' } })} onChange={e => { }} />
            <label htmlFor="gender-o"><span>Ikke spesifisert</span></label>
          </div>

          <div className="input_wrapper">
            <input type="radio" value={true} id="gender-f" checked={item.gender === 'F'} onClick={e => this.setState({ item: { ...item, gender: 'F' } })} onChange={e => { }} />
            <label htmlFor="gender-f"><span>Kvinne</span></label>
          </div>

          <div className="input_wrapper">
            <input type="radio" value={true} id="gender-m" checked={item.gender === 'M'} onClick={e => this.setState({ item: { ...item, gender: 'M' } })} onChange={e => { }} />
            <label htmlFor="gender-m"><span>Mann</span></label>
          </div>
        </div>

      </div>
    )
  }

  _render_selectSimMethod = () => {
    const { item } = this.state
    const { layout } = this.props
    const { departmentId } = layout.data
    const { chatDepartmentIds } = config

    return (
      <div style={{ marginTop: '2rem' }}>
        <h1>SIM-nummer</h1>
        {/*!formData.phone && <div>Nb: Sim nummer kan kun fylles ut om tlf.nr er fyllt ut</div>*/}
        <div className="input_wrapper">
          <input type="radio" value={true} id="sim-auto" checked={item.simMethod === 'auto'} onClick={() => {
            this.setState({ item: { ...item, simMethod: 'auto', simNumber: '' } })
          }} onChange={e => { }} />
          <label htmlFor="sim-auto"><span>Talkmore sender nytt SIM-kort i posten</span></label>
        </div>

        {chatDepartmentIds.includes(departmentId) &&
          <div className="input_wrapper">
            <input type="radio" value={true} id="sim-pickup" checked={item.simMethod === 'pickup'} onClick={() => {
              this.setState({ item: { ...item, simMethod: 'pickup', simNumber: 'hentesim' } })
            }} onChange={e => { }} />
            <label htmlFor="sim-pickup"><span>Kunden henter SIM-kort selv</span></label>
          </div>
        }

        <div className="input_wrapper">
          <input type="radio" value={true} id="sim-input" checked={item.simMethod === 'input'} onClick={() => {
            this.setState({ item: { ...item, simMethod: 'input', simNumber: '' } })
          }} onChange={e => { }} />
          <label htmlFor="sim-input"><span>Skriv inn SIM-nummer som skal brukes</span></label>
        </div>
        {item.simMethod === 'input' &&
          <span>SIM-nummer må være 12 tegn langt. Lar du feltet stå tomt sendes nytt SIM-kort i posten.</span>
        }
      </div>
    )
  }

  _render_simNumberInput = () => {
    const { item } = this.state

    return (
      <div className={`input_wrapper ${this.state.errors && this.state.errors.simNumber ? 'error' : ''} ${this.state.valid && this.state.valid.simNumber ? 'validated' : ''}`}>
        <input type="text"
          /*disabled={formData.phone ? '': 'disabled'}*/
          placeholder="Fyll inn SIM-nummer"
          value={item.simNumber || ''}
          onChange={e => {
            const value = e.target.value
            this.setState({ item: { ...item, simNumber: value } })
          }}
          onBlur={e => {
            this.setState({ errors: validate({ simNumber: item.simNumber }, constraints) })
          }}
          onKeyUp={e => {
            const value = e.target.value

            if (value.length === 12) {
              this.setState({ valid: { simNumber: true } })
              this.setState({ errors: validate({ simNumber: item.simNumber }, constraints) })
            } else {
              this.setState({ valid: { simNumber: true } })
              this.setState({ errors: validate({ simNumber: item.simNumber }, constraints) })
            }

            this.setState({ item: { ...item, simNumber: value } })
          }}
        />
      </div>
    )
  }

  _render_recruitedBy = () => {
    const { item } = this.state

    return (
      <div className="input_wrapper">
        <label htmlFor="sim-pickup"><span>Rekruttert av</span></label>
        <input type="text" id="recruited-by" value={item.recruitedBy || ''} onChange={
          e => this.setState({ item: { ...item, recruitedBy: e.target.value } })
        } />
      </div>
    )
  }

  _render_ownerInput = () => {
    const { item } = this.state

    return (
      <React.Fragment>
        <h1>Personalia</h1>
        <OwnerInput component={this} person={item} mode="single" handleChange={this.handleChange} hidePortType={true} />
      </React.Fragment>
    )
  }

  _render_extraServices = () => {
    const { item, extraServices } = this.state

    return (
      <ExtraServices parent={this} item={item} extraServices={extraServices} toggleExtraService={this.toggleExtraService} />
    )
  }

  _render_buttons = () => {
    return (
      <div className="buttons">
        <button className="cancel" onClick={this.close}>Avbryt</button>
        <button className="continue" onClick={this.showAddOnModal} disabled={!this.allowSave()}>Lagre</button>
      </div>
    )
  }

  _render_blackFriday = () => {
    const { item } = this.state

    if (specialCampaignSubIds.includes(item.subscriptionId)) {
      return <SpecialCampaign mode="single" />
    } else {
      return false
    }
  }

  renderFragmentMaybe = fragment => {
    if (!this.showFragment(fragment)) return false
    return this[`_render_${fragment}`]()
  }

  showFragment = fragment => {
    const { item, showAddOnModal } = this.state
    const { layout, formData } = this.props
    const { phoneItems } = formData
    const { departmentId } = layout.data
    const { chatDepartmentIds } = config
    const { purchaseMethod, simMethod } = item
    const personLookupOK = this.doPersonLookupCheck()
    const preCheckOK = this.doPreCheck()
    const operatorCheckOK = this.doOperatorCheck()
    const fullCheckOK = personLookupOK && preCheckOK && operatorCheckOK

    if (showAddOnModal && fragment === 'addOnModal') {
      return true
    }

    if (!showAddOnModal) {
      switch (fragment) {
        case 'newOrPort':
        case 'blackFriday':
          return true
        case 'buttons':
          return true
        case 'debugInfo':
          return config.showingDebugInfo
        case 'selectOperator':
          return purchaseMethod === 'port'
        // case 'portPhone':
        //   return operatorCheckOK && purchaseMethod === 'port'
        case 'subscription':
          return operatorCheckOK
        case 'campaigns':
          // return operatorCheckOK && item.portPhone && item.subscriptionId
          return operatorCheckOK && purchaseMethod === 'port' && item.subscriptionId
        case 'ownerInput':
          return departmentId === config.ukraineDepartmentId
            || (operatorCheckOK && item.subscriptionId && !phoneItems.length)
        case 'portDate':
          return operatorCheckOK && purchaseMethod === 'port' && item.subscriptionId
        case 'familyMemberDetails':
          return fullCheckOK && !this.isOwner() && item.subscriptionId
        case 'selectSimMethod':
          return fullCheckOK && purchaseMethod === 'port'
        case 'simNumberInput':
          return fullCheckOK && purchaseMethod === 'port' && simMethod === 'input'
        case 'extraServices':
          return fullCheckOK
        case 'recruitedBy':
          return fullCheckOK && chatDepartmentIds.includes(departmentId)
        default:
          return false
      }
    }
  }

  orderedFragments = () => {
    // const { item } = this.state
    // const { purchaseMethod } = item
    const { layout } = this.props
    const { departmentId } = layout.data

    if (departmentId === config.ukraineDepartmentId) {
      return ['debugInfo', 'ownerInput', 'buttons']
    } else {
      const pre = [
        'addOnModal',
        'debugInfo',
        'newOrPort',
      ]
      const variable = [
        'selectOperator',
        // 'portPhone',
        'subscription',
        // 'blackFriday',
        'campaigns',
        'ownerInput',
        'familyMemberDetails',
        'portDate',
        'selectSimMethod',
        'simNumberInput',
        'extraServices',
      ]
      const post = [
        'recruitedBy',
        'buttons',
      ]

      return pre.concat(variable).concat(post)
    }

    // return [
    //   'addOnModal',
    //   'debugInfo',
    //   'newOrPort',
    //   'selectOperator',
    //   'subscriptionAndCampaigns',
    //   'ownerInput',
    //   'portDate',
    //   'familyMemberDetails',
    //   'selectSimMethod',
    //   'simNumberInput',
    //   'extraServices',
    //   'recruitedBy',
    //   'buttons',
    // ]
  }

  render() {
    const { subscriptions } = this.props

    if (!subscriptions.length) return <div />

    return (
      <div>
        {this.orderedFragments().map(fragment => this.renderFragmentMaybe(fragment))}
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    formData: state.formData,
    layout: state.layout,
  }
}

const AddPhoneItem = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddPhoneItemComponent)

export default AddPhoneItem
