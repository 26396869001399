import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import axios from 'axios'
import countries from '../../lib/countries'

import config from '../../config'
import { setFormData } from '../../actions/formData'
import lookupPerson from '../../lib/person_lookup'
import { randStr } from '../../lib/utils'
import PortType from '../PortType'
import DateOfBirthPicker from '../DateOfBirthPicker'

const SMS_POLL_TIMEOUT = 1000

class OwnerInputComponent extends Component {
  constructor(props) {
    super(props)
    this.lookupPerson = lookupPerson.bind(props.component)

    this.state = {
      doingSmsLookup: false,
      missingAddress: '',
      missingPostalCode: '',
      missingPostalArea: '',
    }
  }

  initiateSMSVerification = () => {
    const { appData, person } = this.props
    const subscription = appData.subscriptions.find(s => s.id === person.subscriptionId)
    this.setState({ clientId: randStr(8) }, () => {
      const data = {
        phone: this.props.person.ownerPhone,
        client_id: this.state.clientId,
        type: 'single_owner',
        monthly_cost: subscription.amount,
        package_name: subscription ? subscription.name : null,
      }
      this.setState({ doingSmsLookup: true })
      axios.post(`${config.newApiUrl}/rpc/initiate_sms_verification`, data)
        .then(r => {
          console.log('reply from server', r)
          if (r.data.status === 'ok') {
            this.setState({ serverId: r.data.server_id })
            this.checkSMSTimeout = setTimeout(this.checkSMSStatus, SMS_POLL_TIMEOUT)
          }
        })
        .catch(e => {
          toastr.error('Kunne ikke starte SMS-verifisering', `Vennligst prøv på nytt, eller kontakt...`)
          this.setState({ doingSmsLookup: false })
        })
    })
  };

  checkSMSStatus = () => {
    clearTimeout(this.checkSMSTimeout)
    const { component, person, mode, formData } = this.props
    const personKey = mode === 'family' ? 'owner' : 'item'
    const data = { phone: this.props.person.ownerPhone, client_id: this.state.clientId, server_id: this.state.serverId }
    axios.post(`${config.newApiUrl}/rpc/check_sms_verification`, data)
      .then(r => {
        console.log('reply from server', r)
        if (r.data.status === 'ok') {
          clearTimeout(this.checkSMSTimeout)
          this.setState({ doingSmsLookup: false })
          this.props.dispatch(setFormData({ singleExistingOwnerPhoneNo: this.props.person.ownerPhone }))

          component.setState({
            [personKey]: {
              ...person,
              verified: true,
            }
          })
        } else if (r.data.status === 'pending') {
          this.checkSMSTimeout = setTimeout(this.checkSMSStatus, SMS_POLL_TIMEOUT)
        }
      })
      .catch(e => {
        console.log('error checking SMS status', e)
        // toastr.error('Kunne ikke starte SMS-verifisering', `Vennligst prøv på nytt, eller kontakt...`)
        this.checkSMSTimeout = setTimeout(this.checkSMSStatus, 5000)
      })
  };

  doSmsPersonLookup = () => {
    this.initiateSMSVerification()
  };

  updatePersonData = () => {
    const { component, person, mode } = this.props
    if ((person.address || this.state.missingAddress) && (person.postalCode || this.state.missingPostalCode) && (person.postalArea || this.state.missingPostalArea)) {
      const personKey = mode === 'family' ? 'owner' : 'item'
      component.setState({
        [personKey]: {
          ...person,
          verified: true,
          partlyVerified: false,
          address: person.address || this.state.missingAddress,
          postalCode: person.postalCode || this.state.missingPostalCode,
          postalArea: person.postalArea || this.state.missingPostalArea,
        }
      })
    }
  }

  doFakePersonLookup = () => {
    const { component, person, mode } = this.props
    const personKey = mode === 'family' ? 'owner' : 'item'
    component.setState({
      [personKey]: {
        ...person,
        // verified: true,
        verified: false,
        partlyVerified: true,
        // address: 'Fjellveien 18 B',
        postalCode: '3121',
        postalArea: 'Nøtterøy',
        dateOfBirth: '1974-09-10'
      }
    })
  };

  doPersonLookup = () => {
    const { person, mode } = this.props
    const { formData } = this.props
    const { lookupMethod } = formData
    this.lookupPerson(lookupMethod, person, mode)
  };

  setLookupMethod = lookupMethod => {
    this.props.dispatch(setFormData({ lookupMethod }))
  };

  showPersonalia = () => {
    const { component, mode, person } = this.props
    return (
      (mode === 'single' && (component.state.ownerWantsSubscription || person.verified)) ||
      (mode === 'family' && component.state.ownerWantsSubscription)
    )
  }

  render() {
    const { formData, appData, layout, component, person, handleChange, mode, hidePortType } = this.props
    const { lookupMethod } = formData
    const { departmentId } = layout.data
    const isUkraineFlow = departmentId === config.ukraineDepartmentId
    const personKey = mode === 'family' ? 'owner' : 'item'
    const familyExtraServices = appData.familyExtraServices || []
    const filteredFamilyExtraServices = config.campaignsAsExtraServices.map(c => { return { id: c[1], name: c[0], active: true } })
      .concat(familyExtraServices)
      .filter(s => s.active && s.id !== config.digitalSafetyId)

    return !component ? false : (
      <React.Fragment>
        {!isUkraineFlow &&
          <div className="input_wrapper more-spacing">
            <div className="input_wrapper">
              <input type="radio" value={true} id="owner-wants-sub" checked={component.state.ownerWantsSubscription} onClick={e => {
                component.setState({
                  ownerWantsSubscription: true,
                  [personKey]: {
                    ...component.state[personKey],
                    extraAbroad: e.target.checked ? person.extraAbroad : false,
                    extraAbroadId: e.target.checked ? person.extraAbroadId : null,
                  },
                });
              }} onChange={e => { }} />
              <label htmlFor="owner-wants-sub">
                <span>
                  {mode === 'family' && 'Eier skal kjøpe abonnement til seg selv også'}
                  {mode === 'single' && 'Eier kjøper abonnement til seg selv'}
                </span>
              </label>
            </div>
            <div className="input_wrapper">
              <input type="radio" value={false} id="use-existing-sub" checked={!component.state.ownerWantsSubscription} onClick={e => {
                component.setState({
                  ownerWantsSubscription: false,
                  [personKey]: {
                    ...component.state[personKey],
                    useExistingOwner: true,
                    extraAbroad: e.target.checked ? person.extraAbroad : false,
                    extraAbroadId: e.target.checked ? person.extraAbroadId : null,
                  },
                });
              }} onChange={e => { }} />
              <label htmlFor="use-existing-sub">
                <span>
                  {mode === 'family' && 'Knytt familie til eierens eksisterende Talkmore-abonnement'}
                  {mode === 'single' && 'Knytt bruker til eksisterende Talkmore-abonnement'}
                </span>
              </label>
            </div>
          </div>
        }
        {!isUkraineFlow && !component.state.ownerWantsSubscription && !person.verified &&
          <div>
            <div className="input_wrapper"><label htmlFor="ownerPhone">Vi sender en SMS til juridisk eier for å godkjenne kjøpet - skriv inn mobilnummeret til juridisk eier under:</label>
              <input type="phone" disabled={this.state.doingSmsLookup} id="ownerPhone" name="ownerPhone" value={person.ownerPhone || ''} onChange={(e) => handleChange(e, true)} />
              {this.state.doingSmsLookup &&
                <div className="box" style={{ marginTop: '2rem', backgroundColor: '#fff' }}>
                  <div>Venter på bekreftelse...</div>
                  <button onClick={e => this.initiateSMSVerification()}>Send SMS på nytt</button>
                </div>
              }
              {!this.state.doingSmsLookup &&
                <div className="buttons">
                  <button className="search" onClick={() => this.doSmsPersonLookup()}>
                    Send SMS til juridisk eier for godkjenning
                  </button>
                </div>
              }
            </div>
          </div>
        }

        {!isUkraineFlow && !component.state.ownerWantsSubscription && person.verified &&
          <div className="owner-verified-container" style={{ marginBottom: '2rem' }}>
            <i className="fa-icon fas fa-check"></i> Eier har godkjent kjøpet
          </div>
        }

        {this.showPersonalia() &&
          <div>
            <div className="input_wrapper"><label htmlFor="firstName">Fornavn og mellomnavn:</label>
              <input type="text" id="firstName" name="firstName" value={person.firstName || ''} onChange={(e) => handleChange(e, !person.useExistingOwner)} />
            </div>
            <div className="input_wrapper"><label htmlFor="lastName">Etternavn:</label>
              <input type="text" id="lastName" name="lastName" value={person.lastName || ''} onChange={(e) => handleChange(e, !person.useExistingOwner)} />
            </div>
            {!isUkraineFlow &&
              <React.Fragment>
                <div className="input_wrapper"><label htmlFor="phone">Telefonnr.:</label>
                  <input type="text" id="phone" name="phone" value={person.phone || ''} onChange={handleChange} />
                </div>
                <div className="input_wrapper"><label htmlFor="email">E-post:</label>
                  <input id="email" type="email" name="email" value={person.email || ''} onChange={handleChange} />
                </div>
              </React.Fragment>
            }
            {(isUkraineFlow || (mode === 'single' && formData.singleExistingOwnerPhoneNo)) &&
              <DateOfBirthPicker
                selected={person.dateOfBirth}
                onChange={value => handleChange({ target: { name: 'dateOfBirth', value: value } })}
              />
            }
            {isUkraineFlow &&
              <React.Fragment>
                <div className="input_wrapper"><label htmlFor="email">E-post:</label>
                  <input id="email" type="email" name="email" value={person.email || ''} onChange={handleChange} />
                </div>
                <div className="input_wrapper"><label htmlFor="address">Adresse:</label>
                  <input type="text" id="address" name="address" value={person.address || ''} onChange={handleChange} />
                </div>
                <div className="input_wrapper"><label htmlFor="postalCode">Postnummer:</label>
                  <input id="postalCode" type="text" name="postalCode" value={person.postalCode || ''} onChange={handleChange} />
                </div>
                <div className="input_wrapper"><label htmlFor="postalArea">Poststed:</label>
                  <input id="postalArea" type="text" name="postalArea" value={person.postalArea || ''} onChange={handleChange} />
                </div>
                <div className="input_wrapper"><label htmlFor="simNumber">SIM-nummer:</label>
                  <input id="simNumber" type="text" name="simNumber" value={person.simNumber || ''} onChange={handleChange} />
                </div>
              </React.Fragment>
            }

            {!isUkraineFlow && (mode === 'family' || (mode === 'single' && component.state.ownerWantsSubscription)) &&
              <div className="input_wrapper"><label htmlFor="socialSecurityNumber">Personnr.:</label>
                <input type="text" id="ssn" name="ssn" placeholder="Fyll inn personnummer" value={person.ssn || ''} onChange={(e) => handleChange(e, !person.useExistingOwner)} />
              </div>
            }
          </div>
        }

        {!isUkraineFlow &&
          <div className="person-lookup-container">
            {!person.verified && config.isDebug && component.state.ownerWantsSubscription &&
              <div className="debug_element">
                <div className="buttons">
                  <button className="search" disabled={(!person.firstName || !person.lastName || !person.ssn || person.ssn.length !== 11) || person.verified} onClick={() => this.doFakePersonLookup()}>
                    Gjør FAKE oppslag mot Folkeregisteret
                  </button>
                </div>
              </div>
            }

            {!person.verified && component.state.ownerWantsSubscription &&
              <div>
                <div className="buttons">
                  <button className="search" disabled={(!person.firstName || !person.lastName || !person.ssn || person.ssn.length !== 11) || person.verified} onClick={() => this.doPersonLookup()}>
                    {lookupMethod === 'dsf' && 'Gjør oppslag mot Folkeregisteret'}
                    {lookupMethod === 'dm' && 'Gjør kredittsjekk'}
                  </button>
                </div>
                {lookupMethod === 'dsf' &&
                  <div className="switch-lookup-method-container">
                    <span className="link" onClick={() => this.setLookupMethod('dm')}>Bytt til kredittsjekk (kun hvis folkeregisteret ikke fungerer)</span>
                  </div>
                }
                {lookupMethod === 'dm' &&
                  <div className="switch-lookup-method-container">
                    <span className="link" onClick={() => this.setLookupMethod('dsf')}>Bytt til oppslag mot folkeregisteret</span>
                  </div>
                }
              </div>
            }
          </div>
        }

        {!person.verified && person.partlyVerified &&
          <div className="partly-verified-container">
            <div className="expl">Personen ble funnet i Folkeregisteret, men det mangler noen felter som må fylles ut før du kan gå videre. Fyll inn de manglende feltene under og trykk Oppdater. Alternativt kan du endre personalia over og gjøre et nytt oppslag mot Folkeregisteret.</div>
            {!person.address &&
              <div className="input_wrapper"><label htmlFor="missing_address">Adresse:</label>
                <input id="missing_address" type="text" value={this.state.missingAddress || ''} onChange={ev => this.setState({ missingAddress: ev.target.value })} />
              </div>
            }
            {!person.postalCode &&
              <div className="input_wrapper"><label htmlFor="missing_postal_code">Postnummer:</label>
                <input id="missing_postal_code" type="text" value={this.state.missingPostalCode || ''} onChange={ev => this.setState({ missingPostalCode: ev.target.value })} />
              </div>
            }
            {!person.postalArea &&
              <div className="input_wrapper"><label htmlFor="missing_postal_area">Poststed:</label>
                <input id="missing_postal_area" type="text" value={this.state.missingPostalArea || ''} onChange={ev => this.setState({ missingPostalArea: ev.target.value })} />
              </div>
            }
            <div className="buttons">
              <button disabled={!((person.address || this.state.missingAddress) && (person.postalCode || this.state.missingPostalCode) && (person.postalArea || this.state.missingPostalArea))} onClick={() => this.updatePersonData()}>
                Oppdater
              </button>
            </div>
          </div>
        }

        {mode === 'family' && person.verified && component.state.ownerWantsSubscription &&
          <React.Fragment>
            <h3>Tilleggstjenester for eier/familie</h3>
            {filteredFamilyExtraServices.map(svc => {
              const extraServices = person.extraServices || []
              const existingIdx = extraServices.findIndex(s => s === svc.id)
              return (
                <div key={svc.id}>
                  <div className="input_wrapper">
                    <input type="checkbox"
                      value={1}
                      id={`owner_${svc.id}`}
                      checked={existingIdx !== -1}
                      onChange={e => {
                        const newExtraServices = e.target.checked
                          ? existingIdx === -1 ? extraServices.concat([svc.id]) : extraServices
                          : existingIdx === -1 ? extraServices : extraServices.slice(0, existingIdx).concat(extraServices.slice(existingIdx + 1))
                        component.setState({
                          ...component.state,
                          owner: {
                            ...component.state.owner,
                            extraServices: newExtraServices
                          }
                        })
                      }} />
                    <label htmlFor={`owner_${svc.id}`}><span>{svc.name}</span></label>
                  </div>
                </div>
              )
            })}
          </React.Fragment>
        }

        {person.verified && Boolean(component.state.ownerWantsSubscription && !hidePortType) &&
          <React.Fragment>
            <h3>Nytt nummer eller portering</h3>
            <PortType component={component} person={person} parentObject={personKey} />
          </React.Fragment>
        }
      </React.Fragment>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    appData: state.appData,
    formData: state.formData,
    layout: state.layout,
  }
}

const OwnerInput = connect(
  mapStateToProps,
  mapDispatchToProps
)(OwnerInputComponent)

export default OwnerInput
