import React, { Component } from 'react'
import { connect } from 'react-redux'

class EditPhoneItemComponent extends Component {
  constructor(props) {
    super(props)
    const { appData, formData, layout, dispatch } = props
    const { phoneItems, family } = formData
    const item = phoneItems[props.editIndex]
    this.state = {
      edits: {}
    }
  }

  render() {
    const { appData, formData, layout, dispatch } = this.props
    const { phoneItems, family } = formData
    const item = phoneItems[this.props.editIndex]

    return (
      <div>
        <h3>Rediger bruker</h3>
        <div>
          <div class="input_wrapper">
            <label for="firstName">Fornavn:</label>
            <input
              type="text"
              id="firstName"
              disabled
              defaultValue={this.state.edits.firstName || item.firstName}
            // value={this.state.edits.firstName || item.firstName}
            // onChange={ev => this.setState({ edits: { ...this.state.edits, firstName: ev.target.value } })}
            />
          </div>
          <div class="input_wrapper">
            <label for="lastName">Etternavn:</label>
            <input
              type="text"
              id="lastName"
              disabled
              defaultValue={this.state.edits.lastName || item.lastName}
            // value={this.state.edits.lastName || item.lastName}
            // onChange={ev => this.setState({ edits: { ...this.state.edits, lastName: ev.target.value } })}
            />
          </div>
          <div class="input_wrapper">
            <label for="phone">Telefonnr:</label>
            <input
              type="text"
              id="phone"
              value={this.state.edits.phone || item.phone}
              onChange={ev => this.setState({ edits: { ...this.state.edits, phone: ev.target.value } })}
            />
          </div>
          <div class="input_wrapper">
            <label for="email">E-post:</label>
            <input
              type="text"
              id="email"
              value={this.state.edits.email || item.email}
              onChange={ev => this.setState({ edits: { ...this.state.edits, email: ev.target.value } })}
            />
          </div>
        </div>
        <div className="buttons">
          <button className="cancel" onClick={this.props.cancel}>Avbryt</button>
          <button className="continue" onClick={() => this.props.save(this.state.edits)}>Lagre</button>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  }
}

const mapStateToProps = state => {
  return {
    formData: state.formData,
    appData: state.appData,
    layout: state.layout,
  }
}

const EditPhoneItem = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPhoneItemComponent)

export default EditPhoneItem
