import moment from 'moment'

/**
 * Defines the minimum required number of members in a family.
 *
 * @constant
 * @type {number}
 * @default
 */
const MINIMUM_REQUIRED_MEMBERS = 2

/**
 * Validate that there is a selected package and
 * that there are at least two members in the family.
 *
 * @param {object} family - The family object.
 *
 * @returns {boolean}
 */
export const validateFamilyPackages = family => {
  family = family || {}

  const totalCount = family.counts.adult + family.counts.child;

  return totalCount >= MINIMUM_REQUIRED_MEMBERS && family.selectedPackageId
}

/**
 * Validate that the owner is verified and that all the
 * required fields are present and valid.
 *
 * @param {object} owner - The owner object.
 *
 * @returns {boolean}
 */
export const validateFamilyOwner = (owner, family) => {
  console.log('VAL owner', owner)
  console.log('fam', family)
  return owner && (
    owner.verified &&
    (
      (family.ownerWantsSubscription &&
        owner.purchaseMethod &&
        owner.firstName &&
        owner.lastName) ||
      // owner.phone && owner.phone.length === 8 &&
      // owner.email &&
      ((!family.ownerWantsSubscription || (owner.ssn && owner.ssn.length === 11)) &&
        (!owner.extraAbroad || owner.extraAbroadId) &&
        (!family.ownerWantsSubscription ||
          (owner.purchaseMethod === 'new' ||
            (owner.purchaseMethod === 'port' &&
              owner.phone &&
              (owner.simMethod === 'auto' ||
                ((owner.simMethod === 'pickup' || owner.simMethod === 'input') &&
                  owner.simNumber)
              )
            )
          )
        )
      )
    )
  )
}

/**
 * Validate that the member is set and that all the
 * required fields are present and valid.
 *
 * @param {object} member - The member object.
 *
 * @returns {boolean}
 */
export const validateFamilyMember = (member, family) => {
  if (member.owner) {
    return validateFamilyOwner(member, family)
  }

  return (
    member.firstName &&
    member.lastName &&
    validateFamilyMemberDateOfBirth(member) &&
    // member.email &&
    (member.useOwnerAddress || (member.address && member.postalCode && member.postalArea)) &&
    (!member.extraAbroad || member.extraAbroadId) &&
    (member.purchaseMethod === 'new' ||
      (member.purchaseMethod === 'port' &&
        member.phone &&
        (member.simMethod === 'auto' ||
          ((member.simMethod === 'pickup' || member.simMethod === 'input') &&
            member.simNumber)
        )
      )
    )
  )
}

/**
 * Validate that family with all its members is valid and
 * that all required fields are present and valid.
 *
 * @param {object} family - The family object.
 *
 * @returns {boolean}
 */
export const validateFamily = family => {
  const totalCount = family.counts.adult + family.counts.child

  return (
    validateFamilyPackages(family) &&
    family.members.every(m => validateFamilyMember(m, family)) &&
    family.digitalSafety !== null &&
    totalCount >= MINIMUM_REQUIRED_MEMBERS
  )
}

/**
 * Validate that the provided date of birth for the member
 * is valid based on the member type. Adults must be more
 * than 18 years old, children must be less than 18 years old.
 *
 * @param {object} member - The member object.
 *
 * @returns {boolean}
 */
export const validateFamilyMemberDateOfBirth = member => {
  const dateOfBirth = moment(member.dateOfBirth)

  return (
    member.dateOfBirth &&
    dateOfBirth.isValid()
  )
}
