import React, { Component } from 'react';
import { connect } from 'react-redux'
import axios from 'axios'
import config from '../../config'

import Header from '../Header'
import MainFlow from '../MainFlow'
import GeoPosition from '../GeoPosition'
import Loading from '../Loading'

import { setFormData } from '../../actions/formData'
import { setAppData } from '../../actions/appData'

class DashboardComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
    }
  }

  componentDidMount() {
    document.cookie = 'tminternal=internal;path=/;domain=talkmore.no;max-age=31536000'

    const { dispatch } = this.props
    axios.get(`${config.newApiUrl}/fieldsales/bootstrap`)
      .then(r => {
        console.log('bootstrap data', r.data);
        const { settings } = r.data
        if (settings && Object.keys(settings).length) {
          dispatch(setFormData({
            lookupMethod: settings.customer_lookup_method,
            signMethod: settings.customer_sign_method || 'signature',
            geoCollectionMode: settings.geo_collection_mode || 'forced',
          }))
        }
        dispatch(setAppData({
          subscriptions: r.data.subscriptions.filter(p => !p.bot_key.startsWith('Familiedata')),
          powerPlans: r.data.power_plans,
          campaigns: r.data.campaigns,
          extraServices: r.data.product_options,
          familyDataPackages: r.data.subscriptions.filter(p => p.bot_key.startsWith('Familiedata')),
          familyExtraServices: r.data.product_options.filter(p => !p.bot_key.startsWith('Familiedata')),
        }))
        this.setState({ loaded: true })
      })
      .catch(e => {
        console.log('bootstrap failed', e);
        this.setState({ loaded: true })
      })
  }

  toggleDebugInfo = ev => {
    const { dispatch } = this.props
    ev.preventDefault()
    if (config.showingDebugInfo) {
      window.localStorage.removeItem('showing_debug_info')
      config.showingDebugInfo = false
      console.log('DISABLING debug info')
    } else {
      window.localStorage.setItem('showing_debug_info', '1')
      config.showingDebugInfo = true
      console.log('ENABLING debug info')
    }
    this.setState({ dummy: Math.random() })
    dispatch(setFormData({ dummy: Math.random() }))
  };

  toggleDebugMinimized = ev => {
    const { dispatch } = this.props
    ev.preventDefault()
    if (config.debugSize === 'minimized') {
      window.localStorage.setItem('debug_size', 'normal')
      config.debugSize = 'normal'
    } else {
      window.localStorage.setItem('debug_size', 'minimized')
      config.debugSize = 'minimized'
    }
    this.setState({ dummy: Math.random() })
    dispatch(setFormData({ dummy: Math.random() }))
  };

  clearStoredState = ev => {
    ev.preventDefault()
    window.localStorage.removeItem('_state')
    window.location.reload()
  }

  render() {
    const { formData, appData } = this.props
    const { loaded } = this.state
    const content = formData.geoSkipped || formData.geoCollectionMode === 'disabled' || formData.latitude
      ? <MainFlow />
      : <GeoPosition />
    return (
      <main className={`dashboard ${config.showingDebugInfo && config.debugSize !== 'minimized' ? 'showing-debug-info' : ''} ${config.showingDebugInfo && config.debugSize === 'minimized' ? 'showing-minimized-debug-info' : ''}`}>
        {config.isDebug &&
          <div className={`debug_container ${config.debugSize}`}>
            {config.showingDebugInfo && config.debugSize !== 'minimized' &&
              <div className="debug_element">
                <h2>Form data</h2>
                <pre>{JSON.stringify(formData, null, 2)}</pre>
              </div>
            }
            <div className="debug_notice">
              <button className="small" onClick={this.toggleDebugInfo}>{config.showingDebugInfo ? 'Skjul debug-info' : 'Vis debug-info'}</button>
              &nbsp;&nbsp;
              <button className="small" onClick={this.toggleDebugMinimized}>{config.debugSize === 'minimized' ? 'Forstørr' : 'Minimér'}</button>
              &nbsp;&nbsp;
              <button className="small" onClick={this.clearStoredState}>Slett lagret state</button>
            </div>
          </div>
        }
        <Header />
        {loaded && content}
        {!loaded &&
          <Loading />
        }
      </main>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  }
}

const mapStateToProps = state => {
  return {
    formData: state.formData,
    appData: state.appData,
    user: state.user,
    layout: state.layout
  }
}

const Dashboard = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardComponent);

export default Dashboard