import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import config from '../../config'

import { setFormData } from '../../actions/formData'

class GeoPositionComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      geoError: null
    }
  }

  componentDidMount() {
  }

  getLocation = cb => {
    if (window.location.hostname === 'srv1') {
      this.props.dispatch(setFormData({ latitude: -1, longitude: -1 }))
      return
    }
    const success = (position) => {
      console.log('Position:', position)
      const latitude = position.coords.latitude
      const longitude = position.coords.longitude

      this.props.dispatch(setFormData({ latitude, longitude }))
      if (typeof cb === 'function') {
        cb()
      }
    }

    const error = e => {
      console.log('location error', e)
      // toastr.error('Klarte ikke å bestemme posisjon')
      this.setState({ geoError: e.message })
      if (typeof cb === 'function') {
        cb()
      }
    }

    this.setState({ geoError: null }, () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(success, error)
      }
    })
  };

  skip = () => {
    this.props.dispatch(setFormData({ geoSkipped: true }))
  };

  render() {
    const { formData } = this.props
    const lsOverride = window.localStorage.getItem('_geo_override')
    return (
      <div className="lvl-tm geo-banner-container step__main">
        <div className="geo-banner step__main__inner">
          <React.Fragment>
            <div className="heading-and-button">
              <h1>Enhetens posisjon</h1>
              {(lsOverride || formData.geoCollectionMode === 'skippable') &&
                <span className="link" onClick={this.skip}>Hopp over</span>
              }
            </div>
            <div className="infobox">Posisjonen til denne enheten er ikke tilgjengelig<span onClick={this.skip}>.</span></div>
          </React.Fragment>
          <div className="geo-expl">
            Før du kan ta i bruk verktøyet må vi bestemme enhetens posisjon. Trykk på knappen under og svar Ja/OK på evt. spørsmål som dukker opp.
          </div>
          <div className="buttons">
            <button onClick={() => this.getLocation()}>Hent posisjon</button>
          </div>
          {this.state.geoError &&
            <div className="geo-error-container">
              <div className="geo-error-intro">
                Det oppsto en feil under henting av posisjon:
              </div>
              <div className="geo-error">
                {this.state.geoError}
              </div>
              <div className="geo-error-outro">
                Hvis du tidligere har takket nei til å dele posisjonen din må du kanskje inn i enhetens innstillinger for å tillate dette.
              </div>
              <div className="geo-error-outro">
                <a target="_blank" href="https://docs.buddypunch.com/en/articles/919258-how-to-enable-location-services-for-chrome-safari-edge-and-android-ios-devices-gps-setting">Her er oppskrifter</a>  for hvordan du gjør dette i de mest vanlige nettleserne.
              </div>
              {!['skippable_on_error', 'skippable'].includes(formData.geoCollectionMode) &&
                <div className="geo-error-outro">
                  Verktøyet kan ikke brukes hvis vi ikke kan bestemme posisjonen din.
                </div>
              }
              {(lsOverride || ['skippable_on_error', 'skippable'].includes(formData.geoCollectionMode)) &&
                <div className="buttons">
                  <button onClick={() => this.skip()}>Gå videre likevel</button>
                </div>
              }
            </div>
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    formData: state.formData,
  }
}

const GeoPosition = connect(
  mapStateToProps,
)(GeoPositionComponent);

export default GeoPosition