import React from 'react'
import { connect } from 'react-redux'

// import { unsetModal } from '../../actions/modal'
// import { resetFormData } from '../../actions/formData'

const ModalComponent = ({ modal, dispatch }) => {
  let content = null

  if (modal && modal.show) {
    content = (
      <div className="lightbox_wrapper">
        <div className="lightbox_content">
          <h1>{modal.heading}</h1>
          {modal.body}
          {modal.button}
          { /* 
          <button
            className="secondary large"
            onClick={() => {
              dispatch(resetFormData())
              dispatch(unsetModal())
            }}>Ok</button>
          */ }
        </div>
      </div>
    )
  }

  return content
}

const mapStateToProps = state => {
  return {
    modal: state.modal,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  }
}

const Modal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalComponent);

export default Modal