const initialState = {
  subscriptions: [],
  campaigns: [],
  powerPlans: [],
  extraServices: [],
  familyExtraServices: [],
  familyDataPackages: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case  'SET_APP_DATA':
      const newAppData = {...state, ...action.data}
      window.appData = newAppData
      return newAppData
    default:
      return state;
  }
}