import React, { Component } from 'react'
import DatePicker from 'react-datepicker'

import moment from 'moment'

export default class DateOfBirthPicker extends Component {
  render() {
    return (
      <div className={`input_wrapper ${this.props.error ? 'error' : ''} ${this.props.valid ? 'validated' : ''}`}><label htmlFor="dateofbirth">Fødselsdato:</label>
        <DatePicker
          name="dateOfBirth"
          dateFormat="dd/MM/yyyy"
          className="form-control"
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          minDate={null}
          maxDate={moment().toDate()}
          selected={this.props.selected ? moment(this.props.selected).toDate() : null}
          onChange={e => this.props.onChange(moment(e).format('YYYY-MM-DD'))} />
      </div>
    )
  }
}