import React, { Component } from 'react'
import { connect } from 'react-redux'

class SpecialCampaignComponent extends Component {
  render() {
    const target = this.props.mode === 'single' ? 'abonnement' : 'datapakke'
    return (
      <div className="special-campaign-container">
        <div className="heading">Black Friday-kampanje</div>
        <div className="sub-heading">For valgt {target} gjelder følgende tilbud - kun idag:</div>
        <div className="offer">50% rabatt i 2 måneder</div>
        <div className="fine-print">Gjelder prisen på selve abonnementet (ikke tilleggstjenester) ut inneværende måned og de to neste. Black Friday-tilbudet kan kun kombineres med ekstra evig GB, ikke partnerrabatter som f.eks. OBOS</div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  }
}

const mapStateToProps = state => {
  return {
    formData: state.formData,
  }
}

const SpecialCampaign = connect(
  mapStateToProps,
  mapDispatchToProps
)(SpecialCampaignComponent)

export default SpecialCampaign
