export const setSignedIn = user => {
  return {
    type: 'SET_SIGNED_IN',
  };
}

export const setSignedOut = user => {
  return {
    type: 'SET_SIGNED_OUT',
  };
}
