import React from 'react';
import { connect } from 'react-redux'

import Anon from '../components/Anon'
import Dashboard from '../components/Dashboard'

const AppComponent = ({ authenticate, user, layout }) => {
  let content
  let css_classes_app
  if (authenticate && layout.data && layout.data.departmentId) {
    content = <Dashboard />
    css_classes_app = 'app logged-in'
  } else {
    content = <Anon />
    css_classes_app = 'app not-logged-in'
  }

  return (
    <div className={css_classes_app}>
      {content}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    layout: state.layout,
    user: state.user,
    authenticate: state.authenticate,
  }
}

const App = connect(
  mapStateToProps,
)(AppComponent)

export default App