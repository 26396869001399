export const setFormData = data => {
  return {
    type: 'SET_FORM_DATA',
    data: data,
  };
}

export const resetFormData = data => {
  return {
    type: 'RESET_FORM_DATA',
  };
}