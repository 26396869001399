import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { toastr } from 'react-redux-toastr'
import moment from 'moment'

import { logOut } from '../../auth'

import { resetFormData, setFormData } from '../../actions/formData'
import { setModal, unsetModal } from '../../actions/modal'

import Modal from '../Modal'
import Logo from '../Logo'

import config from '../../config'

const STAND_DEPARTMENTS = [
  '6e8e995c-5070-4071-a487-09948a761d65', // Adicio
  '89139c3e-ae02-4473-9a3d-ceff542c92b7', // AB marketing
]

function nameStr(user) {
  return user.firstName 
    ? (user.firstName + ' ' + (user.lastName || '')).trim()
    : user.email
}

class HeaderComponent extends Component {
  startStand = () => {
    const { user, layout, dispatch } = this.props
    const { departmentId } = layout.data
    // this.props.dispatch(setFormData({isSubmitting: true}))
    axios.post(`${config.newApiUrl}/stand_instances`, {department_id: departmentId})
      .then(r => {
        if (r.data.success) {
          dispatch(setModal({
            show: true,
            heading: 'Stand startet',
            body: <p>Stand startet av {nameStr(user.user)} kl. {moment().format('HH:mm')}.</p>,
            button: <button className="secondary large" onClick={() => dispatch(unsetModal())}>OK</button>
          }))
        }
      })
      .catch(e => {
        toastr.error('Kunne ikke starte stand', `Vennligst prøv på nytt senere`)
      })
  };

  emptyForm = () => {
    const { dispatch } = this.props
    dispatch(resetFormData())
  };

  render() {
    const { user, layout, dispatch } = this.props
    const standButtonVisible = layout.data && 
      layout.data.departmentId && 
      STAND_DEPARTMENTS.indexOf(layout.data.departmentId) !== -1

    return (
      <header className="site-header">
        <div className="site-header__inner">
          <Logo />
          <nav>
            {standButtonVisible &&
              <button className="stand" onClick={this.startStand}>Start stand</button>
            }
            <button className="empty" onClick={() => this.emptyForm()}>Tøm skjema</button>
            <button className="logout" onClick={() => logOut(dispatch)}>Logg ut {nameStr(user.user)}</button>
          </nav>
          { /* If logged in, display username and logout button, if not logged in dont output anything
          <div>({user.username}) <a href="" onClick={e => {
            e.preventDefault()
            logOut(dispatch)
          }}>Logg ut</a></div>
          */ } 
        </div>
      </header>
    )
  }
}


const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    layout: state.layout,
  }
}

const Header = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderComponent);

export default Header