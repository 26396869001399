export const setHome = path => {
  return {
    type: 'SET_HOME',
    route: path,
  };
}

export const setDepartment = departmentId => {
  return {
    type: 'SET_HOME',
    data: {
      departmentId: departmentId,
    }
  };
}

// export const setInvite = (id) => {
//   window.location.hash = `/invite/${id}`
//   return {
//     type: 'SET_LAYOUT',
//     route: 'INVITE',
//     data: {
//       id: id
//     }
//   };
// }