import React from 'react'
import { connect } from 'react-redux'

import config from '../../../config'
import { resetFormData } from '../../../actions/formData'

function isValid(result) {
  return result &&
    result.data &&
    result.data.order &&
    result.data.order.sales &&
    result.data.order.sales.length
}

function renderDebugInfo(response) {
  const firstSale = response.data.order.sales[0]
  return (
    <div className="discreet">
      <table className="debug">
        <tbody>
          {(firstSale.salesRep || firstSale.salesStore) &&
            <tr>
              <td>Selger:</td>
              <td>{firstSale.salesRep}{firstSale.salesStore ? ` (${firstSale.salesStore})` : ''}</td>
            </tr>
          }
          {response.reference &&
            <tr>
              <td>Referanse:</td>
              <td>{response.reference}</td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  )
}

function renderSale(sale, idx) {
  return (
    <tbody key={`sale-${idx}`}>
      <tr className="heading">
        <td colSpan="2">{sale.firstName} {sale.lastName}{sale.isOwner && sale.isOwner === '1' ? ' (eier)' : ''}</td>
      </tr>
      <tr>
        <td>Fødselsdato:</td>
        <td>{sale.dateOfBirth}</td>
      </tr>
      <tr>
        <td>Adresse:</td>
        <td><div>{sale.address}</div><div>{sale.postalCode} {sale.postalArea}</div></td>
      </tr>
      {sale.phoneNumber &&
        <tr>
          <td>Telefonnummer:</td>
          <td>{sale.phoneNumber}</td>
        </tr>
      }
      {sale.email &&
        <tr>
          <td>Epost:</td>
          <td>{sale.email}</td>
        </tr>
      }
      <tr>
        <td>Abonnement:</td>
        <td>{sale.rateplanName}</td>
      </tr>
      {sale.portDate &&
        <tr>
          <td>Porteringsdato:</td>
          <td>{sale.portDate}</td>
        </tr>
      }
      {sale.icc &&
        <tr>
          <td>SIM-nummer:</td>
          <td>{sale.icc}</td>
        </tr>
      }
      {sale.recruitedBy &&
        <tr>
          <td>Rekruttert av:</td>
          <td>{sale.recruitedBy}</td>
        </tr>
      }
      {Boolean(sale.campaigns && sale.campaigns.length) &&
        <tr>
          <td>Kampanjer:</td>
          <td>{sale.campaigns.map(c => <div>{c}</div>)}</td>
        </tr>
      }
      {Boolean(sale.productOptions && sale.productOptions.length) &&
        <tr>
          <td>Ekstratjenester:</td>
          <td>{sale.productOptions.map(p => <div>{p}</div>)}</td>
        </tr>
      }
      {sale.recruitedBy &&
        <tr>
          <td>Rekruttert av:</td>
          <td>{sale.recruitedBy}</td>
        </tr>
      }
    </tbody>
  )
}

const Step6Component = ({ dispatch, formData }) => {
  const { importResult } = formData
  return (
    <div>
      <div className="step__main">
        <div className="step__main__inner">
          <h1>Registrert!</h1>
          {isValid(importResult) &&
            <React.Fragment>
              <p>Her er kvitteringen fra Talkmore. Vennligst sjekk at alt stemmer.</p>
              <table className="receipt">
                {importResult.data.order.sales.map((s, i) => renderSale(s, i))}
              </table>
              {renderDebugInfo(importResult)}
            </React.Fragment>
          }
          {!isValid(importResult) &&
            <React.Fragment>
              <p>Bestillingen gikk gjennom, men det ble ikke returnert en kvittering fra Talkmore.</p>
            </React.Fragment>
          }
          <button className="restart" onClick={() => dispatch(resetFormData())}>Registrere ny kunde</button>
          {config.isDebug &&
            <div className="debug_element" style={{ marginTop: '4rem' }}>
              <pre>
                {JSON.stringify(importResult, null, 2)}
              </pre>
            </div>
          }
        </div>
      </div>
    </div>
  )
}


const mapDispatchToProps = dispatch => {
  return {
    dispatch: dispatch
  }
}

const mapStateToProps = state => {
  return {
    formData: state.formData,
  }
}

const Step6 = connect(
  mapStateToProps,
  mapDispatchToProps
)(Step6Component);

export default Step6